import { parse } from 'path'
import { ENTITY, API_GET_MAPDATA } from 'src/consts'

export const getFiats = async () => {
  const res = await fetch('/site-api/coins/fiats')
  const data = await res.json()

  if (!Array.isArray(data)) {
    throw new Error('Unexpected API response format')
  }

  return data.map(fiat => ({
    code: fiat.code,
    name: fiat.name,
  }))
}

export const getCoinChartData = async ({ symbol, from, to }) => {
  return fetch(
    `/site-api/coins/${symbol}/graph?start=${Math.floor(
      from / 1000
    )}&end=${Math.floor(to / 1000)}`
  )
    .then(res => res.json())
    .then(parseChartDataResponse)
}

const parseChartDataResponse = res => {
  if (!Array.isArray(res)) {
    throw new Error('Wrong API response format')
  }

  if (!res.length) {
    return null
  }

  const data = res.reverse().reduce(
    (acc, i) => {
      acc.price.push({
        x: i[3] * 1000,
        y: i[0],
      })

      acc.mc.push({
        x: i[3] * 1000,
        y: i[1],
      })

      acc.volume.push({
        x: i[3] * 1000,
        y: i[2],
      })
      return acc
    },
    { price: [], mc: [], volume: [] }
  )

  const from = res[0][3] * 1000
  const to = res[res.length - 1][3] * 1000

  return { ...data, from, to }
}

export const getMarketsData = async ({
  entity,
  currency = 'usd',
  slug,
  url = '',
}) => {
  // const coinsDict = getCoinsDict(state)

  let path = {
    [ENTITY.COIN]: `/site-api/coins/${slug}/markets`,
    [ENTITY.EXCHANGE]: `/site-api/exchanges/${slug}/markets`,
  }[entity]

  if (currency !== 'usd') {
    path += `?currency=${currency}`
  }

  const res = await fetch(`${url}${path}`).then(res => res.json())

  const DATA_MAP = {
    [ENTITY.COIN]: (market, index) => ({
      ...market,
      price: market.price < 0 ? 0 : market.price,
      market: `${market.base}/${market.quote}`,
      index: index + 1,
    }),
    [ENTITY.EXCHANGE]: (market, index) => ({
      ...market,
      exchangePair: `${market.base}/${market.quote}`,
      // coin: coinsDict[market.base],
      index: index + 1,
    }),
  }

  return res?.map(DATA_MAP[entity])
}

export const getCoinDirectData = async (slug, currency) => {
  const currencyQuery = currency ? `?currency=${currency}` : ''
  return fetch(`${API_GET_MAPDATA}/${slug}/card${currencyQuery}`).then(res =>
    res.json()
  )
}
