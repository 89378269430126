import AdButlerPlacement from '../AdButlerPlacement'

// imports from './styles.module.scss'

const LayoutMenuButton = ({ horizontal = false }) => {
  return (
    <AdButlerPlacement
      place={horizontal ? '591298' : '590061'}
      forceProxy={true}
    />
  )
}

export default LayoutMenuButton
