import styles from './styles.module.scss'
import useOnClickOutside from 'use-onclickoutside'
import { useState, useRef } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

export const Dropdown = ({
  title,
  titleBlock,
  children,
  showArrow,
  wrapperClassName,
  listClassName,
  currencyLogo,
  buttonClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const toggleOpen = e => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClose = () => setIsOpen(false)

  useOnClickOutside(ref, handleClose)

  return (
    <div className={clsx(styles.dropdown, buttonClassName)} ref={ref}>
      <div
        className={clsx(styles.block, wrapperClassName, {
          [styles.isOpen]: isOpen,
        })}
        onClick={toggleOpen}
      >
        {currencyLogo && (
          <Image
            src={currencyLogo}
            style={{ marginRight: '8px' }}
            width='17px'
            height='17px'
            alt='Currency logo'
          />
        )}
        {titleBlock}
        <div className={styles.title}>{title}</div>
        {showArrow && (
          <div className={clsx(styles.icon, { [styles.isOpen]: isOpen })}>
            <Image
              src='/assets/icons/icon-arrow-down.svg'
              width='7px'
              height='7px'
              alt='Arrow icon'
            />
          </div>
        )}
      </div>
      {isOpen && (
        <div className={clsx(styles.list, listClassName)}>{children}</div>
      )}
    </div>
  )
}

export default Dropdown
