import GlobalSearch from '../GlobalSearch'
import styles from './styles.module.scss'
import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { Search } from 'src/pages/NFT/components/Search'
import Image from 'next/image'

export const SearchDesktop = () => {
  const [searchVisible, setSearchVisible] = useState<boolean>(false)
  const [searchCloseVisible, setSearchCloseVisible] = useState<boolean>(false)
  const router = useRouter()
  const isNft = router.pathname.includes('/nft')
  const searchRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setSearchVisible(false)
        setSearchCloseVisible(false)
      }
    }

    const handleKeyPress = event => {
      if (event.key === 'Escape') {
        setSearchVisible(false)
        setSearchCloseVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const handleSearchImageClick = () => {
    setSearchVisible(prevState => !prevState)
    setSearchCloseVisible(prevState => !prevState)
  }

  return (
    <div className={styles.searchContainer} ref={searchRef}>
      {searchVisible ? (
        <>
          {!isNft && <GlobalSearch className={styles.globalSearch} />}
          {isNft && <Search className={styles.globalSearch} />}
        </>
      ) : null}
      <div className={styles.searchImage} onClick={handleSearchImageClick}>
        <Image
          src={
            searchCloseVisible
              ? '/assets/icons/icon-menu-close.svg'
              : '/assets/icons/icon-search-green.svg'
          }
          alt='Information'
          width={18}
          height={18}
        />
      </div>
    </div>
  )
}
