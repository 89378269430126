import { Icon } from 'src/components/Icon'
import clsx from 'clsx'
import styles from './styles.module.scss'

const GetLine = ({ data }) => {
  const { name } = data
  const valueSearch = data.valueSearch.toLowerCase()

  if (valueSearch === '' || name.indexOf(valueSearch) === -1) {
    return name
  }
  const valueArray = name.split(valueSearch)
  return (
    <span>
      {`${valueArray[0]}`}
      <span
        className={styles.titleUnderline}
        style={{
          textTransform:
            valueArray[0] === '' || valueArray[0].substr(-1) === '-'
              ? 'capitalize'
              : 'none',
        }}
      >
        {valueSearch}
      </span>
      {`${valueArray[1]}`}
    </span>
  )
}

export const DrawerRow = ({
  data,
  className,
  entity,
  symbolAction,
  withoutTitle,
}) => {
  const { name, slug, symbol } = data

  return (
    <div className={clsx(styles.drawerRow, className)}>
      <div className={styles.titleBox}>
        <span className={styles.symbolAction}>{symbolAction}</span>
        <div className={styles.iconContainer}>
          <Icon data={{ name, slug, symbol }} size='16x16' entity={entity} />
        </div>
        <div
          className={clsx(styles.symbol, {
            [styles.withoutTitle]: withoutTitle,
          })}
        >
          {symbol}
        </div>
        {!withoutTitle && (
          <div className={styles.title}>
            <GetLine data={data} /> &nbsp;
          </div>
        )}
      </div>
    </div>
  )
}
