// src/components/Tab/index.js
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import styles from './styles.module.scss'
import useWidth from 'src/hooks/useWidth'
import debounce from 'lodash.debounce'

const Tab = React.memo(({ tab, active, changeTab, totalTabs }) => {
  const tabRef = useRef(null)
  const [tabWidth, setTabWidth] = useState(0)
  const { isMobile } = useWidth()

  const displayName =
    tab.name.length > 20 ? `${tab.name.slice(0, 20)}...` : tab.name

  useEffect(() => {
    setTabWidth(tabRef.current.offsetWidth)
  }, [])

  useEffect(() => {
    const handleResize = debounce(() => {
      setTabWidth(tabRef.current.offsetWidth)
    }, 300)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const shouldHideTextAndActive = useCallback(() => {
    if (window.innerWidth <= 1025) {
      return false
    } else if (
      window.innerWidth > 1025 &&
      window.innerWidth <= 1145 &&
      totalTabs > 3
    ) {
      if (totalTabs >= 6 && active) {
        return true
      }
      return !active
    } else if (
      window.innerWidth > 1145 &&
      window.innerWidth <= 1245 &&
      totalTabs > 7
    ) {
      if (totalTabs >= 11 && active) {
        return true
      }
      return !active
    }
    return false
  }, [totalTabs, active])

  if (isMobile) {
    return (
      <div
        ref={tabRef}
        className={clsx(styles.tabMobile, { [styles.active]: active })}
        onClick={() => changeTab(tab)}
      >
        <div className={styles.iconMobile}>
          <Image
            className={styles.tabIcon}
            src='/assets/icons/icon-watchlist.svg'
            width={18}
            height={18}
            alt='close icon'
          />
        </div>
        <div
          className={clsx(styles.tabNameMobile, { [styles.active]: active })}
        >
          {displayName}
        </div>
      </div>
    )
  } else {
    return (
      <div
        ref={tabRef}
        className={clsx(styles.tabDesktop, { [styles.active]: active })}
        onClick={() => changeTab(tab)}
      >
        <div className={styles.closerWrapper}>
          {active ? (
            <button className={styles.closer}>
              <Image
                className={styles.tabIcon}
                src='/assets/icons/icon-watchlist.svg'
                width={50}
                height={50}
                alt='close icon'
              />
            </button>
          ) : (
            <Image
              className={styles.tabIcon}
              src='/assets/icons/icon-watchlist.svg'
              width={24}
              height={24}
              alt='close icon'
            />
          )}
        </div>
        {shouldHideTextAndActive() ? null : (
          <div className={clsx(styles.tabName, { [styles.active]: active })}>
            {displayName}
          </div>
        )}
      </div>
    )
  }
})

export default Tab
