import clsx from 'clsx'
import styles from './styles.module.scss'

export const Toggle = ({
  active,
  icon,
  iconActive,
  onClick,
  children,
  className,
  classNameIcon,
}) => {
  return (
    <div
      className={clsx(styles.toggle, className, { [styles.active]: active })}
      onClick={onClick}
    >
      <div className={clsx(styles.icon, classNameIcon)}>
        {iconActive && active ? iconActive : icon}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default Toggle
