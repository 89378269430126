import Head from 'next/head'
import { useRouter } from 'next/router'
import { memo, useMemo } from 'react'
import { Thing, WebPage, WithContext } from 'schema-dts'
import { SEO_IMAGE } from 'src/consts/common'
import { BASE_URL } from 'src/consts/env'

export type JsonLd<T extends Thing> = WithContext<T>

export type SeoMetaProps<T extends Thing> = {
  keywords?: string
  description: string
  jsonLd?: T
  robots?: string
  title: string
  canonical?: string
  image?: string
}

export const SeoMeta = memo(
  <T extends Thing>({
    description,
    keywords,
    jsonLd,
    robots,
    title,
    canonical,
    image: propImage,
  }: SeoMetaProps<T>) => {
    const router = useRouter()
    const path = `${BASE_URL}${router.asPath}`

    const image = propImage || SEO_IMAGE

    const jsonLdString = useMemo(() => {
      const defaultJsonSchema: JsonLd<WebPage> = {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: title,
        description,
        url: `${path}`,
        image: image,
        sameAs: [
          'https://www.reddit.com/r/Coin360',
          'https://twitter.com/coin360com',
          'https://www.facebook.com/Coin360com/',
          'https://t.me/COIN360com',
          'https://mt.linkedin.com/company/coin360',
          'https://www.tiktok.com/@coin360com?lang=en',
          'https://www.youtube.com/@COIN-li4tr/about',
        ],
      }

      return JSON.stringify(jsonLd || defaultJsonSchema)
    }, [description, jsonLd, title, path, image])

    return (
      <Head>
        <title>{title}</title>
        {canonical && <link rel='canonical' href={canonical} />}
        <meta name='description' content={description} />
        <meta name='image' content={image} />
        <meta property='og:title' content={title} />
        <meta property='og:url' content={`${BASE_URL}${path}`} />
        <meta property='og:image' content={image} />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='600' />
        <meta property='og:image:height' content='315' />
        <meta property='og:site_name' content='COIN360' />
        <meta property='og:description' content={description} />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:site' content='@COIN360com' />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={image} />
        {keywords && <meta name='keywords' content={keywords} />}
        {robots && <meta name='robots' content={robots} />}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: jsonLdString }}
        />
      </Head>
    )
  }
)
