import React, { useState, useCallback, useEffect } from 'react'
import styles from './styles.module.scss'
import Image from 'next/image'
import { ENTITY } from 'src/consts'

export const Icon = ({
  data,
  entity,
  size = '16x16',
  forceIconSize = false,
  className = '',
}) => {
  const { name, slug } = data
  const [error, setError] = useState(false)

  const sizesToNumberSize = {
    '16x16': 16,
    '32x32': 32,
    '48x48': 48,
    '57x57': 57,
    '64x64': 64,
    '128x128': 128,
    '256x256': 256,
  }

  const getPath = useCallback(
    domain => {
      const sizeToAdaptive = {
        '16x16': '32x32',
        '32x32': '64x64',
        '48x48': '64x64',
        '64x64': '128x128',
      }

      const sizeIcon = forceIconSize || size || '16x16'
      const adSize = !!sizeToAdaptive[sizeIcon]
        ? sizeToAdaptive[sizeIcon]
        : sizeIcon
      switch (entity) {
        case ENTITY.COIN:
          return `${domain}/icons/slug-coins/${adSize}/${slug}.png`
        case ENTITY.EXCHANGE:
          return `${domain}/icons/exchanges/${adSize}/${slug}.png`

        default:
          throw new Error('wrong entity type')
      }
    },
    [entity, forceIconSize, size, slug]
  )
  // TEMPORARY until images will not manages via CMS
  const image = getPath(process.env.NEXT_PUBLIC_API_ENDPOINT)

  useEffect(() => {
    setError(false)
  }, [image])

  const getFontSize = size => (size > 16 ? size / 2 : '0.7 rem')

  return (
    <div className={className}>
      {!image && !error ? (
        <div className={styles.loaderBox}>
          <div
            className={styles.loaderItem1}
            style={{ width: sizesToNumberSize[size] }}
          />
          <div
            className={styles.LoaderItem2}
            style={{ width: sizesToNumberSize[size] }}
          />
        </div>
      ) : image && !error ? (
        <Image
          src={image}
          alt={
            entity === ENTITY.COIN
              ? `${name} price, market cap on Coin360 heatmap`
              : `${name} trading pairs and volume on Coin360`
          }
          width={sizesToNumberSize[size]}
          height={sizesToNumberSize[size]}
          onError={() => setError(true)}
        />
      ) : (
        <div
          className={styles.errorImage}
          style={{
            width: sizesToNumberSize[size],
            height: sizesToNumberSize[size],
          }}
        >
          <span style={{ fontSize: getFontSize(sizesToNumberSize[size]) }}>
            ?
          </span>
        </div>
      )}
    </div>
  )
}
