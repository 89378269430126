import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { intl } from 'src/i18n'
import useWidth from 'src/hooks/useWidth'
import { setLatestActiveTab } from 'src/redux/actions'
import {
  getAllWatchListItems,
  selectWatchlistLoading,
  getActiveWatchlist,
} from 'src/redux/selectors'
import { changeActiveWatchList, deleteAllWatchListTabs } from 'src/redux/thunks'
import Tab from '../Tab'
import styles from './styles.module.scss'
import Dialog from 'src/components/Dialog'

const Tabs = () => {
  const { isMobile } = useWidth()
  const dispatch = useDispatch()
  const router = useRouter()
  const isLoading = useSelector(selectWatchlistLoading)
  const allWatchLists = useSelector(getAllWatchListItems)
  const tabActive = useSelector(getActiveWatchlist)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const handleChange = useCallback(
    async tab => {
      await router.push(`/watchlist/${tab.id}`)
      dispatch(changeActiveWatchList(tab.id))
      dispatch(setLatestActiveTab(tab.id))
    },
    [dispatch, router]
  )

  const handleAddNewWatchlist = () => {
    router.push('/watchlist')
  }

  const handleDeleteAllWatchlists = () => {
    setIsConfirmDialogOpen(true)
  }

  const confirmDeleteAll = async () => {
    setIsConfirmDialogOpen(false)
    await dispatch(deleteAllWatchListTabs())
    router.push('/')
  }

  const renderedTabs = useMemo(
    () =>
      allWatchLists.map((tab, index) => (
        <Tab
          key={tab.id}
          numId={index}
          tab={tab}
          changeTab={handleChange}
          active={router.asPath.includes(tab.id)}
          totalTabs={allWatchLists.length}
        />
      )),
    [allWatchLists, handleChange, tabActive, router.asPath]
  )

  if (isLoading) {
    return <div className='tabPlaceholder'></div>
  }

  if (isMobile) {
    return (
      <>
        <div className={styles.tabsWrapperMobile}>
          {renderedTabs}
          {allWatchLists.length < 10 ? (
            <div
              className={styles.addTabMobile}
              onClick={handleAddNewWatchlist}
            >
              <p className={styles.addTextMobile}>
                {intl.t('watchList.components.tabs.addWatchlist')}
              </p>
              <Image
                src={'/assets/icons/icon-plus-green.svg'}
                width={24}
                height={24}
                alt={'icon add'}
              />
            </div>
          ) : (
            <div
              className={styles.deleteButtonMobile}
              onClick={handleDeleteAllWatchlists}
            >
              <p className={styles.deleteAllTextMobile}>
                {intl.t('watchList.components.tabs.deleteAll')}
              </p>
            </div>
          )}
        </div>
        <Dialog
          dismissable
          headerless
          padding={false}
          onClose={() => setIsConfirmDialogOpen(false)}
          open={isConfirmDialogOpen}
          title=''
        >
          <div className={styles.confirmDeleteDialog}>
            <p>{intl.t('watchList.components.tabs.confirmDelete')}</p>
            <div className={styles.dialogButtons}>
              <button onClick={confirmDeleteAll}>
                {intl.t('watchList.components.tabs.confirm')}
              </button>
              <button onClick={() => setIsConfirmDialogOpen(false)}>
                {intl.t('watchList.components.tabs.cancel')}
              </button>
            </div>
          </div>
        </Dialog>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.tabsContainer}>
          <div className={styles.tabsWrapper}>
            {renderedTabs}
            {allWatchLists.length < 10 ? (
              <div className={styles.addTab} onClick={handleAddNewWatchlist}>
                {renderedTabs.length === 0 && (
                  <p className={styles.addText}>
                    {intl.t('watchList.components.tabs.addWatchlist')}
                  </p>
                )}
                <Image
                  src={'/assets/icons/icon-plus-green.svg'}
                  width={24}
                  height={24}
                  alt={'icon add'}
                />
              </div>
            ) : (
              <div
                className={styles.deleteButton}
                onClick={handleDeleteAllWatchlists}
              >
                <p className={styles.deleteAllText}>
                  {intl.t('watchList.components.tabs.deleteAll')}
                </p>
              </div>
            )}
          </div>
        </div>
        <Dialog
          dismissable
          headerless
          padding={false}
          onClose={() => setIsConfirmDialogOpen(false)}
          open={isConfirmDialogOpen}
          title=''
        >
          <div className={styles.confirmDeleteDialog}>
            <p>{intl.t('watchList.components.tabs.confirmDelete')}</p>
            <div className={styles.dialogButtons}>
              <button onClick={confirmDeleteAll}>
                {intl.t('watchList.components.tabs.confirm')}
              </button>
              <button onClick={() => setIsConfirmDialogOpen(false)}>
                {intl.t('watchList.components.tabs.cancel')}
              </button>
            </div>
          </div>
        </Dialog>
      </>
    )
  }
}

export default Tabs
