import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import NativePlacement from './NativePlacement'
import ProxyPlacement from './ProxyPlacement'

const isBrowser = () => typeof window !== 'undefined'

const AdButlerPlacement = ({
  place,
  className,
  onEmpty,
  forceProxy = false,
  adjustHeight = false,
}) => {
  const [placeUID, setPlaceUid] = useState(null)
  useEffect(() => {
    if (placeUID == null) {
      setPlaceUid(uuidv4())
    }
  }, [placeUID])

  if (placeUID == null) {
    return null
  }

  if (!isBrowser) {
    return null
  }

  if (window.canShowAd && !forceProxy) {
    return (
      <NativePlacement
        place={place}
        uid={placeUID}
        className={className}
        onEmpty={onEmpty}
        adjustHeight={adjustHeight}
      />
    )
  } else {
    return (
      <ProxyPlacement
        place={place}
        uid={placeUID}
        className={className}
        onEmpty={onEmpty}
      />
    )
  }
}

export default AdButlerPlacement
