// src/hooks/useWatchlist.js
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useCallback } from 'react'
import { getActiveWatchlist, getAllWatchListItems } from 'src/redux/selectors'
import {
  addNewWatchListTab,
  getWatchlistTabs,
  changeActiveWatchList,
} from 'src/redux/thunks'
import { useRouter } from 'next/router'

export const useWatchlist = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { isAuth } = useSelector(state => state.auth)
  const tabActive = useSelector(getActiveWatchlist)
  const tabs = useSelector(getAllWatchListItems)

  useEffect(() => {
    if (isAuth) {
      dispatch(getWatchlistTabs({ isAuth }))
    }
  }, [dispatch, isAuth])

  const addTab = useCallback(async () => {
    if (isAuth) {
      const newTabId = await dispatch(addNewWatchListTab())
      if (newTabId) {
        await router.push(`/watchlist/${newTabId}`)
      }
    }
  }, [dispatch, isAuth, router])

  return {
    tabActive,
    tabs,
    addTab,
  }
}
