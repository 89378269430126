import styles from './styles.module.scss'
import { getAllNewMenuItems } from '../../helpers/menuItems'
import LayoutMenuButton from 'src/components/Banners/LayoutMenuButton'
import { Item } from './components/Item'

export const Menu = () => {
  const items = getAllNewMenuItems()
  return (
    <div className={styles.headerMenu}>
      <LayoutMenuButton horizontal={false} />
      {items
        .filter(item => !item.onlyMobile)
        .map(item => {
          return <Item key={item.title} item={item} />
        })}
    </div>
  )
}

export default Menu
