import { Icon } from 'src/components/Icon'
import styles from './styles.module.scss'
import compact from 'lodash/compact'

export const Exceptions = ({ currentItemsDict, deleteItem, data, entity }) => {
  const exceptions = compact(
    Object.keys(currentItemsDict).map(slg => data.find(d => d.slug === slg))
  )

  return exceptions.length > 0 ? (
    <div className={styles.coins}>
      {exceptions.map((exception, key) => (
        <div key={key} className={styles.coin}>
          <div key={key} className={styles.coinData}>
            <div className={styles.iconContainer}>
              <Icon data={exception} size='16x16' entity={entity} />
            </div>
            <span>{exception.symbol}</span>
            <span
              className={styles.clickable}
              onClick={() => deleteItem(exception.slug)}
            >
              &times;
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  )
}
