import { CONFIG } from 'src/consts'

export const compareToExtremum = (timestamp, extremum) => {
  extremum = extremum.toLowerCase()

  if (extremum === 'min') {
    return timestamp > CONFIG.BASE_TIMESTAMP
  } else {
    return timestamp < new Date().getTime()
  }
}
