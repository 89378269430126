import React from 'react'
import { Item } from './components/Item'
import styles from './styles.module.scss'
export const SearchResult = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.resultContainer}>
        {data?.map((item, index) => {
          return <Item item={item} key={index} />
        })}
      </div>
    </div>
  )
}
