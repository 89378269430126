import MobileView from './components/MobileView'
import DesktopView from './components/DesktopView'
import useWidth from 'src/hooks/useWidth'

export const SearchBox = props => {
  const { isMobile } = useWidth()
  return isMobile ? <MobileView {...props} /> : <DesktopView {...props} />
}

export default SearchBox
