import 'rc-slider/assets/index.css'
import './styles.module.scss'
import styles from './styles.module.scss'
import Slider from 'rc-slider'
import clsx from 'clsx'
import numeral from 'numeral'
import { useEffect, useState } from 'react'

const { createSliderWithTooltip } = Slider
const RangeWrapper = createSliderWithTooltip(Slider.Range)

export const RangeFilter = ({
  className,
  maxValue,
  withInputs,
  marksDisabled,
  fullWidth,
  step,
  value,
  action,
  disabled,
  label,
  isMobile,
}) => {
  const [range, setRange] = useState(value)

  useEffect(() => {
    setRange(value)
  }, [value])

  const calculateRelativeInputValue = type => {
    const rawValue = type === 'min' ? range[0] : range[1]
    const mathFunction = type === 'min' ? Math.floor : Math.round
    const comparisonSign = type === 'min' ? '>' : '<'

    let del = 1000000

    switch (true) {
      case rawValue > 10000000:
        del = 500000
        break
      case rawValue > 500000:
        del = 10000
        break
      case rawValue > 100000:
        del = 5000
        break
      case rawValue > 10000:
        del = 1000
        break
      case rawValue > 1000:
        del = 500
        break
      case rawValue > 500:
        del = 100
        break
      case rawValue <= 500:
        del = 10
        break
      default:
        del = 1000000
        break
    }

    return rawValue === 0
      ? `${rawValue}`
      : `${comparisonSign}${numeral(mathFunction(rawValue / del) * del).format(
          '0,0'
        )}`
  }

  const handleChange = newValue => setRange(newValue)

  const onAfterChange = () => action(range)

  return (
    <div
      className={clsx(className, {
        [styles.noMarks]: marksDisabled,
        [styles.rangeFilterMobile]: isMobile,
        [styles.rangeFilter]: !isMobile,
      })}
    >
      {isMobile && label && <div className={styles.label}>{label}</div>}
      {withInputs && (
        <div
          className={clsx({
            [styles.info]: isMobile,
          })}
        >
          <div className={styles.inputsContainer}>
            <input
              type='text'
              className={clsx(styles.controlInput, {
                [styles.fullWidth]: fullWidth,
              })}
              value={calculateRelativeInputValue('min')}
              disabled
            />
            <span className={styles.delimeterDash}>-</span>
            <input
              type='text'
              className={clsx(styles.controlInput, {
                [styles.fullWidth]: fullWidth,
              })}
              value={calculateRelativeInputValue('max')}
              disabled
            />
          </div>
        </div>
      )}
      <div className={clsx(styles.slider, { [styles.mobile]: isMobile })}>
        <RangeWrapper
          min={0}
          max={maxValue}
          step={step}
          value={range}
          onChange={handleChange}
          onAfterChange={onAfterChange}
          disabled={disabled}
          tipFormatter={value => numeral(value).format('0,0')}
          tipProps={{
            placement: 'bottomLeft',
          }}
          className={styles.rcSlider}
        />
      </div>
    </div>
  )
}

export default RangeFilter
