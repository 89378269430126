import clsx from 'clsx'
import { useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useWidth from 'src/hooks/useWidth'
import {
  getNewsState,
  getCoinsGlobalSelector,
  getCurrentCurrencySelector,
  getCoinsPeriodSelector,
} from 'src/redux/selectors'
import { setNewsState } from 'src/redux/actions'
import { combinedAnalytics } from 'src/utils/common'
import { NewsTicker, NewsDropOut } from './components'
import styles from './styles.module.scss'

export const NewsFeed = memo(({ newsFeedData = false }) => {
  const [isOpen, toggleOpen] = useState(false)
  const newsState = useSelector(getNewsState)
  const globals = useSelector(getCoinsGlobalSelector)
  const currency = useSelector(getCurrentCurrencySelector)
  const coinsPeriod = useSelector(getCoinsPeriodSelector)

  const dispatch = useDispatch()
  const { isMobile } = useWidth()
  const hideNewsFeed = () => dispatch(setNewsState(false))

  const toggleNewsFeed = () => {
    toggleOpen(!isOpen)
    combinedAnalytics(
      'Button',
      'Button',
      'News Ticker',
      `${!isOpen ? 'Expand' : 'Collapse'}`
    )
  }

  const handleNewsInteraction = url => {
    combinedAnalytics('Outbound Links', 'Outbound Links', 'News:ticker', url)
  }

  if (isMobile) return null

  return (
    <div
      className={clsx(styles.newsFeed, {
        [styles.opened]: isOpen,
        [styles.hide]: !newsState,
      })}
    >
      {isOpen ? (
        <NewsDropOut
          globals={newsFeedData ? newsFeedData : globals}
          currency={currency}
          periodsActive={coinsPeriod.active}
          handleNewsInteraction={handleNewsInteraction}
        />
      ) : (
        <NewsTicker
          globals={newsFeedData ? newsFeedData : globals}
          currency={currency}
          periodsActive={coinsPeriod.active}
          handleNewsInteraction={handleNewsInteraction}
        />
      )}
      <div
        className={clsx(styles.closer, { [styles.opened]: isOpen })}
        onClick={hideNewsFeed}
      />
      <div
        className={clsx(styles.opener, { [styles.inverted]: isOpen })}
        onClick={toggleNewsFeed}
      />
    </div>
  )
})

export default NewsFeed
