import { useRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import useOnClickOutside from 'use-onclickoutside'
import styles from './styles.module.scss'

export const CustomDropdown = ({
  title = undefined,
  customTitle,
  titleBlock = undefined,
  titleValue,
  children,
  showArrow,
  wrapperClassName = undefined,
  listClassName = undefined,
  currencyLogo = undefined,
  isOpen,
  setIsOpen,
}) => {
  const ref = useRef(null)

  const toggleOpen = e => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleClose = () => setIsOpen(false)

  useOnClickOutside(ref, handleClose)

  return (
    <div className={styles.dropdown} ref={ref}>
      <div
        className={clsx(styles.block, wrapperClassName, {
          [styles.isOpen]: isOpen,
        })}
        onClick={toggleOpen}
      >
        {currencyLogo && (
          <>
            <span className={styles.currencyLogo}>
              <Image
                src={`/assets/currency/${currencyLogo}-filter-basecurrency.svg`}
                width='17px'
                height='17px'
                alt='Currency logo'
              />
            </span>
            <span className={styles.titleValue}>{currencyLogo}</span>
          </>
        )}
        {titleBlock}
        {title && (
          <div className={styles.title}>
            {/* {title}: Deleted this for now so it doesnt show Perfomance. 8/3 */}
            <span className={styles.titleValue}>{titleValue}</span>
          </div>
        )}
        {!title && customTitle && (
          <div className={styles.title}>
            <span className={styles.titleValue}>{customTitle}</span>
          </div>
        )}
        {showArrow && (
          <div className={clsx(styles.icon, { [styles.isOpen]: isOpen })}>
            <Image
              src='/assets/icons/icon-arrow-down.svg'
              width='7px'
              height='7px'
              alt='Arrow icon'
            />
          </div>
        )}
      </div>
      {isOpen && (
        <div className={clsx(styles.list, listClassName)}>{children}</div>
      )}
    </div>
  )
}

export default CustomDropdown
