import { useState } from 'react'
import styles from './styles.module.scss'
import { SmartInput } from 'src/components/CustomPicker/components/SmartInput'
import {
  getSubtractDays,
  formatDateToTimestamp,
} from 'src/utils/time/moment.helper'
import { PERIODS as periodsConstants } from 'src/consts'
import { intl } from 'src/i18n'

export const CustomDate = ({ periods, onChange, onClose }) => {
  const [startDate, setStartDate] = useState(periods.start)
  const [endDate, setEndDate] = useState(periods.end)

  const applyChanges = () => {
    const newPeriods = {
      active: periodsConstants.keysMirror['custom'],
      start: formatDateToTimestamp(startDate),
      end: formatDateToTimestamp(endDate),
    }
    onChange(newPeriods)
    onClose(false)
  }

  const validatePeriods = newPeriods => {
    if (newPeriods.end) {
      if (newPeriods.end >= new Date().getTime()) {
        newPeriods.end = endDate
      }

      if (newPeriods.end <= startDate) {
        newPeriods.start = formatDateToTimestamp(
          getSubtractDays(newPeriods.end, 1)
        )
        setStartDate(newPeriods.start)
      }
      setEndDate(newPeriods.end)
      return
    }

    if (newPeriods.start) {
      if (
        newPeriods.start >= new Date().getTime() ||
        newPeriods.start >= formatDateToTimestamp(getSubtractDays(endDate, 1))
      ) {
        newPeriods.start = formatDateToTimestamp(getSubtractDays(endDate, 1))
      }
      setStartDate(newPeriods.start)
    }
  }

  const onReset = () => {
    onClose(false)
    setStartDate(periods.start)
    setEndDate(periods.end)
  }

  return (
    <div className={styles.customDate}>
      <div className={styles.header}>
        <div className={styles.title}>
          {intl.t('components.periodFilter.components.customDate.select')}
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formCol}>
          <div className={styles.fieldLabel}>
            {intl.t('components.periodFilter.components.customDate.from')}
          </div>
          <SmartInput
            value={startDate}
            name='min'
            onChange={start => validatePeriods({ start })}
          />
        </div>
        <div className={styles.formCol}>
          <div className={styles.fieldLabel}>
            {intl.t('components.periodFilter.components.customDate.to')}
          </div>
          <SmartInput
            value={endDate}
            name='max'
            onChange={end => validatePeriods({ end })}
          />
        </div>
      </div>
      <div className={styles.actionsRow}>
        <button onClick={() => onReset()}>
          {intl.t('components.periodFilter.components.customDate.clear')}
        </button>
        <button className={styles.buttonApply} onClick={() => applyChanges()}>
          {intl.t('components.periodFilter.components.customDate.applyChanges')}
        </button>
      </div>
    </div>
  )
}

export default CustomDate
