import { createAsyncStateHook } from './utils'
import {
  getNFTCollections,
  getNFTCollection,
  getNFTCollectionAssets,
  getNFTBlockchains,
  getNFTCollectionsSearch,
} from 'src/services/NFTCollectionServices'

export const useNFTCollections = createAsyncStateHook(
  'nftcollections',
  getNFTCollections,
  { refreshInterval: 30000 }
)

export const useNFTCollection = createAsyncStateHook(
  'nftcollection',
  getNFTCollection
)

export const useNFTCollectionAssets = createAsyncStateHook(
  'nftcollectionassets',
  getNFTCollectionAssets
)

export const useNFTBlockchains = createAsyncStateHook(
  'nftblockchains',
  getNFTBlockchains
)

export const useNFTCollectionSearch = createAsyncStateHook(
  'getNFTCollectionsSearch',
  getNFTCollectionsSearch
)
