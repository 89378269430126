import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { currenciesOptions } from 'src/consts'
import Image from 'next/image'
import clsx from 'clsx'
import { getFiats } from 'src/services/coinService'
import { fiatRegions } from 'src/utils/currency/fiatRegions'
import { CurrencySearch } from '../CurrencySearch'

type CurrencyProps = {
  currencyActive: string
  setCurrencyActive: (value: string) => void
}

export const Currency = ({
  currencyActive,
  setCurrencyActive,
}: CurrencyProps) => {
  const [fiatCurrencies, setFiatCurrencies] = useState([])

  useEffect(() => {
    const fetchFiats = async () => {
      try {
        const fiats = await getFiats()
        setFiatCurrencies(fiats)
      } catch (error) {
        console.error('Failed to fetch fiat currencies:', error)
      }
    }
    fetchFiats()
  }, [])

  const allCurrencies = [
    ...currenciesOptions.currency.filter(item => !item.fiat),
    ...fiatCurrencies,
  ]

  const handleSetCurrency = (value: string) => {
    setCurrencyActive(value)
  }

  const renderFiatCurrenciesByRegion = (region: string) => {
    return fiatCurrencies
      .filter(fiat => fiatRegions[region].includes(fiat.code))
      .map(item => (
        <div
          className={clsx(styles.item, {
            [styles.itemActive]: currencyActive === item.code,
          })}
          key={item.code}
          onClick={() => handleSetCurrency(item.code)}
        >
          <div className={styles.itemImage}>
            <Image
              src={`/assets/currency/${item.code}-filter-basecurrency.svg`}
              width='32px'
              height='32px'
              alt={item.code}
            />
          </div>
          <div className={styles.itemData}>
            <span
              className={clsx(styles.label, {
                [styles.labelActive]: currencyActive === item.code,
              })}
            >
              {item.name}
            </span>
            <span
              className={clsx(styles.value, {
                [styles.valueActive]: currencyActive === item.code,
              })}
            >
              {item.code}
            </span>
          </div>
        </div>
      ))
  }

  return (
    <div className={styles.container}>
      <CurrencySearch
        currencies={allCurrencies}
        onCurrencySelect={setCurrencyActive}
        activeCurrency={currencyActive}
      />
      {/* Display cryptocurrency options */}
      <div className={styles.cryptoSection}>
        <h3>Crypto Currencies</h3>
        <div className={styles.listCoins}>
          {currenciesOptions.currency.map(item => {
            if (!item.fiat) {
              return (
                <div
                  className={clsx(styles.item, {
                    [styles.itemActive]: currencyActive === item.value,
                  })}
                  key={item.value}
                  onClick={() => handleSetCurrency(item.value)}
                >
                  <div className={styles.itemImage}>
                    <Image
                      src={`/assets/currency/${item.value}-filter-basecurrency.svg`}
                      width='32px'
                      height='32px'
                      alt={item.value}
                    />
                  </div>
                  <div className={styles.itemData}>
                    <span
                      className={clsx(styles.label, {
                        [styles.labelActive]: currencyActive === item.value,
                      })}
                    >
                      {item.label}
                    </span>
                    <span
                      className={clsx(styles.value, {
                        [styles.valueActive]: currencyActive === item.value,
                      })}
                    >
                      {item.value}
                    </span>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>

      {/* Display fiat currencies by region */}
      <div className={styles.fiatSection}>
        <h3>Fiat Currencies</h3>
        {Object.keys(fiatRegions).map(region => (
          <div key={region} className={styles.region}>
            <h4>{region}</h4>
            <div className={styles.list}>
              {renderFiatCurrenciesByRegion(region)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
