import AdButlerPlacement from '../AdButlerPlacement'
import styles from './styles.module.scss'

export const SkyscraperBanner = () => {
  return (
    <div className={styles.skyscraper}>
      <div className={styles.container}>
        <AdButlerPlacement className={styles.placement} place={'501740'} />
      </div>
    </div>
  )
}

export default SkyscraperBanner
