import React, { useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import styles from './styles.module.scss'

type Currency = {
  code: string
  name: string
  fiat?: boolean
}

type CurrencySearchProps = {
  currencies: Currency[]
  onCurrencySelect: (currencyCode: string) => void
  activeCurrency: string
}

export const CurrencySearch = ({
  currencies,
  onCurrencySelect,
  activeCurrency,
}: CurrencySearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredCurrencies = currencies.filter(
    currency =>
      currency.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      currency.code?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className={styles.searchContainer}>
      <input
        type='text'
        className={styles.searchInput}
        placeholder='Search currencies...'
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {searchTerm.length >= 1 && (
        <div className={styles.currencyList}>
          {filteredCurrencies.map(currency => (
            <div
              key={currency.code}
              className={clsx(styles.currencyItem, {
                [styles.currencyItemActive]: activeCurrency === currency.code,
              })}
              onClick={() => onCurrencySelect(currency.code)}
            >
              <div className={styles.currencyItemImage}>
                <Image
                  src={`/assets/currency/${currency.code}-filter-basecurrency.svg`}
                  width='32px'
                  height='32px'
                  alt={currency.code}
                />
              </div>
              <div className={styles.currencyItemData}>
                <span
                  className={clsx(styles.currencyLabel, {
                    [styles.currencyLabelActive]:
                      activeCurrency === currency.code,
                  })}
                >
                  {currency.name}
                </span>
                <span
                  className={clsx(styles.currencyValue, {
                    [styles.currencyValueActive]:
                      activeCurrency === currency.code,
                  })}
                >
                  {currency.code}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
