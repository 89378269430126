import { useMemo, useRef, useState } from 'react'
import useSWR, { SWRConfiguration, unstable_serialize } from 'swr'
import useSWRInfinite from 'swr/infinite'

export type Status = 'idle' | 'loading' | 'error' | 'success' | 'revalidating'

type StateKey = { key: string; [key: string]: any }

type GetKey<P, K> = string | ((params?: P) => K)

export function createAsyncStateHook<D, P extends object, K extends StateKey>(
  getKey: GetKey<P, K>,
  getData: (params?: P) => Promise<D>,
  config?: SWRConfiguration
) {
  const useStateHook = (params?: P) => {
    const key = generateKey(getKey, params)
    const [state, setState] = useState<D>()
    const swr = useSWR(
      key,
      () =>
        getData(params)
          .then(setState)
          .catch(err => {
            setState(undefined)
            return err
          }),
      config
    )

    const status = useMemo<Status>(() => {
      if (swr.error) return 'error'
      if (swr.isValidating && swr.data) return 'revalidating'
      if (swr.isValidating) return 'loading'
      if (!swr.data) return 'idle'
      return 'success'
    }, [swr.data, swr.isValidating, swr.error])

    return {
      ...swr,
      data: swr.data || state,
      status,
    }
  }

  useStateHook.getData = getData
  useStateHook.getKey = getKey
  useStateHook.getFallback = (params?: P) => ({
    key: generateKey(getKey, params),
    data: getData(params),
  })

  return useStateHook
}

function generateKey<P, K extends StateKey>(
  getKey: GetKey<P, K>,
  params: P
): string {
  const obj =
    typeof getKey === 'function' ? getKey(params) : { ...params, key: getKey }
  return unstable_serialize(obj)
}

export function createInfiniteAsyncStateHook<
  D,
  P extends object,
  K extends StateKey
>(
  getKey: GetKey<P, K>,
  getData: (params?: P) => Promise<D>,
  config?: SWRConfiguration
) {
  const useStateHook = (params?: P) => {
    const infParams = useRef({})
    const keyLoader = (index, previousPageData) => {
      infParams.current = { ...params, index, previousPageData }
      return generateKey(getKey, infParams.current)
    }
    const swr = useSWRInfinite(
      keyLoader,
      () => getData(infParams.current as any),
      config
    )

    const status = useMemo<Status>(() => {
      if (swr.error) return 'error'
      if (swr.isValidating) return 'loading'
      if (!swr.data) return 'idle'
      return 'success'
    }, [swr.data, swr.isValidating, swr.error])

    return { ...swr, status }
  }

  useStateHook.getData = getData
  useStateHook.getKey = getKey
  useStateHook.getFallback = (params?: P) => ({
    key: generateKey(getKey, params),
    data: getData(params),
  })

  return useStateHook
}

export async function combineFallbacks<
  P extends { key: string; data: unknown }[]
>(...params: P): Promise<{ [key: string]: any }> {
  const fallbacks = await Promise.all(params.map(param => param.data))

  return fallbacks.reduce((acu, fallback, i) => {
    acu[params[i].key] = fallback
    return acu
  }, {})
}
