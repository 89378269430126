// src/components/DesktopHeader/index.js
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import Session from 'supertokens-auth-react/recipe/session'
import { ProfileHeader } from 'src/components/Auth'
import { AuthModal } from 'src/components/AuthModal'
import { CurrencyLanguageModal } from 'src/components/CurrencyLanguageModal'
import { Logo } from 'src/components/Logo'
import Tabs from 'src/pages/Watchlist/components/Tabs'
import { Loader } from 'src/components/Loader'
import Menu from '../Menu'
import { SearchDesktop } from '../SearchDesktop'
import { useWatchlist } from '../UseWatchlist'
import styles from './styles.module.scss'

export const DesktopHeader = ({ className, fullScreen, refNode, fixed }) => {
  const router = useRouter()
  const sessionContext = Session.useSessionContext()
  const { isAuth } = useSelector(state => state.auth)
  const [isLoading, setIsLoading] = useState(false)
  const { tabActive, tabs, addTab } = useWatchlist()

  if (isLoading) {
    return <Loader isActive={isLoading} />
  }

  const ProfileSkeleton = () => (
    <div
      style={{ width: '50px', height: '50px', backgroundColor: '#000' }}
    ></div>
  )

  return (
    <header>
      <section
        className={clsx(styles.header, className, {
          [styles.fullScreenHeader]: fullScreen,
          [styles.fixed]: fixed,
        })}
        ref={refNode}
      >
        <div className={styles.left}>
          <div
            className={`${styles.logoContainer} ${
              router.pathname === '/' ? styles.activeLogo : ''
            }`}
          >
            <Logo className={styles.logo} />
          </div>
          {isAuth && <Tabs />}
        </div>
        <div className={styles.right}>
          <Menu />
          <div className={styles.buttonContainer}>
            <SearchDesktop />
            <CurrencyLanguageModal buttonClass={styles.buttonCurrency} />
            {sessionContext.loading ? (
              <ProfileSkeleton />
            ) : sessionContext.doesSessionExist ? (
              <SessionAuth>
                <ProfileHeader userInfo={sessionContext.accessTokenPayload} />
              </SessionAuth>
            ) : (
              <AuthModal />
            )}
          </div>
        </div>
      </section>
    </header>
  )
}

export default DesktopHeader
