import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Dropdown } from 'src/components/Dropdown'
import { LogarithmicRangeFilter } from 'src/components/LogarithmicRangeFilter'
import { SelectBox } from 'src/components/Select'
import { PeriodFilter } from 'src/components/PeriodFilter'
import { HideEntityFilter } from 'src/components/HideEntityFilter'
import { RangeFilter } from 'src/components/RangeFilter'
import { Checkbox } from 'src/components/Checkbox'
import { FiltersLayout } from 'src/layouts'
import { FilterModal } from '../FiltersLayout/components/FilterModal'
import { exchangesFiltersOptions, ENTITY } from 'src/consts'
import {
  changeExchangesPeriod,
  setExchangesTop,
  addExchangesException,
  deleteExchangesException,
  resetExchangesExceptions,
  setExchangesGroup,
  setExchangesRange,
  setExchangesMarketsRange,
  resetExchangesRange,
} from 'src/redux/thunks'
import {
  getDependsOnByEntity,
  getExchangesRange,
  getExchangesTop,
  getExchangesActivePeriod,
  getExchangesPeriod,
  getExchangesMapDataArrayRaw,
  getExchangesExceptionsDict,
  getExchangesGroup,
  getExchangesMapDataMaxValues,
  getExchangesMarketsRange,
} from 'src/redux/selectors'
import { getFieldOfDataObject } from 'src/utils/currency'
import { exchangesFiltersDefaults } from 'src/redux/reducers'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

const periodsFormatter = value => {
  return {
    '1h': intl.t('layouts.exchangesFilterLayout.periodsFormatter.1h'),
    '24h': intl.t('layouts.exchangesFilterLayout.periodsFormatter.24h'),
    '7d': intl.t('layouts.exchangesFilterLayout.periodsFormatter.7d'),
    '30d': intl.t('layouts.exchangesFilterLayout.periodsFormatter.30d'),
    custom: intl.t('layouts.exchangesFilterLayout.periodsFormatter.custom'),
  }[value]
}

export const ExchangesFilterLayout = ({ classNames, coinsPage }) => {
  const dispatch = useDispatch()
  const exchangesRange = useSelector(getExchangesRange)
  const exchangesMarketRange = useSelector(getExchangesMarketsRange)
  const coinsDepends = useSelector(getDependsOnByEntity)
  const maxValueVolumeRange = getFieldOfDataObject(
    useSelector(getExchangesMapDataMaxValues),
    coinsDepends,
    Math.round
  )
  const maxValueMarkets = getFieldOfDataObject(
    useSelector(getExchangesMapDataMaxValues),
    'pairs'
  )
  const exchangesActivePeriod = useSelector(getExchangesActivePeriod)
  const exchangesPeriod = useSelector(getExchangesPeriod)
  const exchangesTop = useSelector(getExchangesTop)
  const exchangesMapDataArray = useSelector(getExchangesMapDataArrayRaw)
  const exceptionsDict = useSelector(getExchangesExceptionsDict)
  const exchangesGroup = useSelector(getExchangesGroup)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  const openModal = () => {
    setIsFilterModalOpen(true)
  }

  const closeModal = () => {
    setIsFilterModalOpen(false)
  }

  return (
    <div>
      <FiltersLayout
        coinsPage={coinsPage}
        classNames={classNames}
        openModal={openModal}
      >
        <PeriodFilter
          title={intl.t('layouts.exchangesFilterLayout.performance')}
          action={data => dispatch(changeExchangesPeriod(data))}
          options={exchangesFiltersOptions.period}
          value={exchangesActivePeriod}
          periods={exchangesPeriod}
          defaultValue={exchangesFiltersDefaults.period.active}
          valueFormatter={periodsFormatter}
        />
      </FiltersLayout>
      {isFilterModalOpen && (
        <FilterModal closeModal={closeModal}>
          <SelectBox
            label={intl.t('layouts.exchangesFilterLayout.gainers')}
            options={exchangesFiltersOptions.top}
            value={exchangesTop}
            defaultValue={exchangesFiltersDefaults.top}
            action={data => dispatch(setExchangesTop(data))}
            instanceId='Gainers'
            isFilter
          />

          <Dropdown
            title={intl.t('layouts.exchangesFilterLayout.volumeRange')}
            showArrow
          >
            <LogarithmicRangeFilter
              maxValue={maxValueVolumeRange}
              value={exchangesRange}
              action={range => dispatch(setExchangesRange(range))}
              reset={range => dispatch(resetExchangesRange(range))}
            />
          </Dropdown>

          <Dropdown
            title={intl.t('layouts.exchangesFilterLayout.markets')}
            showArrow
          >
            <RangeFilter
              maxValue={maxValueMarkets}
              withInputs={true}
              value={exchangesMarketRange}
              action={markets => dispatch(setExchangesMarketsRange(markets))}
            />
          </Dropdown>

          <HideEntityFilter
            entity={ENTITY.EXCHANGE}
            mapDataArray={exchangesMapDataArray}
            currentItemsDict={exceptionsDict}
            addItem={data => dispatch(addExchangesException(data))}
            deleteItem={data => dispatch(deleteExchangesException(data))}
            reset={() => dispatch(resetExchangesExceptions())}
          />
          <div className={styles.wrapper}>
            <Checkbox
              checked={exchangesGroup === 'Verified'}
              label={intl.t('layouts.exchangesFilterLayout.onlyVerified')}
              onClick={() =>
                dispatch(
                  setExchangesGroup(
                    exchangesGroup === 'all' ? 'Verified' : 'all'
                  )
                )
              }
            />
          </div>
        </FilterModal>
      )}
    </div>
  )
}

export default ExchangesFilterLayout
