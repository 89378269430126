import { useState, useCallback } from 'react'
import Slider from 'rc-slider'
import clsx from 'clsx'
import { getRangeFilterMarks } from 'src/utils/getRangeFilterMarks'
import { formatCurrency2CompactString } from 'src/utils/number/currency'
import 'rc-slider/assets/index.css'
import './styles.module.scss'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

const RangeWrapper = Slider.Range

export const LogarithmicRangeFilter = ({
  className,
  maxValue,
  value,
  action,
  isMobile = false,
  label,
  reset,
}) => {
  const [range, setRange] = useState(value)

  const logValue = [
    Number(Math.log(range[0]).toFixed(1)),
    Number(Math.log(range[1]).toFixed(1)),
  ]

  const marks = getRangeFilterMarks(maxValue, 'logarithmic', {
    [maxValue]: formatCurrency2CompactString(maxValue.toString()),
  })

  const handleChange = useCallback(
    ([from, to]) =>
      setRange([
        Number((from === 0 ? 0 : Math.exp(from)).toFixed(0)),
        Number(Math.exp(to).toFixed(0)),
      ]),
    []
  )

  const apply = useCallback(() => {
    action(range)
  }, [action, range])

  const onClear = useCallback(() => {
    reset([0, maxValue], false)
    setRange([0, maxValue])
  }, [reset, maxValue])

  return (
    <div className={clsx(styles.rangeFilter, className)}>
      {isMobile && label && <div className={styles.label}>{label}</div>}
      <div className={styles.info}>
        <p>
          {intl.t('components.logarithmicRangeFilter.from')}{' '}
          <span>{formatCurrency2CompactString(range[0])}$</span>{' '}
          {intl.t('components.logarithmicRangeFilter.to')}{' '}
          <span>{formatCurrency2CompactString(range[1])}</span>
        </p>
        <div className={styles.buttonsWrapper}>
          <button onClick={onClear} className={styles.button}>
            {intl.t('components.logarithmicRangeFilter.reset')}
          </button>
          <button onClick={apply} className={clsx(styles.button, styles.apply)}>
            {intl.t('components.logarithmicRangeFilter.apply')}
          </button>
        </div>
      </div>
      <div className={clsx(styles.slider, { [styles.mobile]: isMobile })}>
        <RangeWrapper
          step={0.000001}
          marks={marks}
          min={0}
          max={Math.log(maxValue)}
          value={logValue}
          onChange={handleChange}
          className={styles.rcSlider}
        />
      </div>
    </div>
  )
}

export default LogarithmicRangeFilter
