import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import useWidth from 'src/hooks/useWidth'
import { useOverlayTriggerState } from '@react-stately/overlays'
import styles from './styles.module.scss'
import { Dialog } from 'src/components/Dialog'
import {
  SignInAndUp,
  ResetPasswordUsingToken,
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import Image from 'next/image'
import useAuth from 'src/hooks/useAuth'

export const AuthModal = ({ buttonClass }) => {
  const { isMobile } = useWidth()
  const dialog = useOverlayTriggerState({})
  const { user } = useAuth()
  const [formResetPassword, setFormResetPassword] = useState(false)
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  const handleOverlayClick = event => {
    // Check if the clicked element is the overlay itself
    if (event.target === event.currentTarget) {
      setIsOverlayVisible(false)
    }
  }

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }

  return (
    <>
      <span
        onClick={toggleOverlay}
        className={clsx(styles.button, {
          [styles.isMobile]: isMobile,
          [buttonClass]: true,
        })}
      >
        <Image
          className={styles.loginImage}
          src={`/assets/icons/icon-user.svg`}
          width='22px'
          height='22px'
          alt='icon user'
        />
        <p className={styles.login}>Log in</p>
      </span>
      {isOverlayVisible && (
        <div className={styles.overlay} onClick={handleOverlayClick}>
          <div
            className={styles.modalContent}
            onClick={e => e.stopPropagation()}
          >
            <div
              className={clsx(styles.iframe, {
                [styles.hideIframe]: formResetPassword,
              })}
            >
              <SignInAndUp />
              {/* <div
                className={styles.reset}
                onClick={() => setFormResetPassword(!formResetPassword)}
              >
                Forgot password?
              </div> */}
            </div>
            <div
              className={clsx(styles.iframe, {
                [styles.hideIframe]: !formResetPassword,
              })}
            >
              <ResetPasswordUsingToken />
              <div
                className={styles.reset}
                onClick={() => setFormResetPassword(!formResetPassword)}
              >
                Return to Login
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
