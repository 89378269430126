import styles from './styles.module.scss'
import Link from 'next/link'
import Image from 'next/image'

export const FooterNavbar = () => {
  return (
    <div className={styles.footer}>
      <Link href={'mailto:contact@coin360.com'}>
        <a className={styles.buttonFooter}>
          <Image
            src={`/assets/icons/icon-mail.svg`}
            width='22px'
            height='22px'
            alt='mail icon'
          />
        </a>
      </Link>
      <Link href={'https://t.me/COIN360Channel'}>
        <a className={styles.buttonFooter} target='_blank'>
          <Image
            src={`/assets/social/icon-telegram.svg`}
            width='22px'
            height='22px'
            alt='telegram icon'
          />
        </a>
      </Link>
      <Link href={'https://twitter.com/coin360com'}>
        <a className={styles.buttonFooter} target='_blank'>
          <Image
            src={`/assets/social/icon-twitter.svg`}
            width='22px'
            height='22px'
            alt='twitter icon'
          />
        </a>
      </Link>
      <Link href={'/newsletter'}>
        <a className={styles.buttonFooter}>
          <Image
            src={`/assets/icons/icon-newsletter.svg`}
            width='22px'
            height='22px'
            alt='newsletter icon'
          />
        </a>
      </Link>
    </div>
  )
}
