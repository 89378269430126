import React, { useState, useEffect } from 'react'
import {
  formatDate,
  getUTCDate,
  isValidCheck,
  formatDateToTimestamp,
} from 'src/utils/time/moment.helper'
import { compareToExtremum } from 'src/utils/time/compareToExtremum.helper'
import styles from './styles.module.scss'

export const SmartInput = ({ value, name, onChange }) => {
  const [nextValue, setNextValue] = useState(
    formatDate(getUTCDate(value), 'dd.MM.yyyy')
  )

  useEffect(() => {
    setNextValue(formatDate(getUTCDate(value), 'dd.MM.yyyy'))
  }, [value])

  const handleChange = e => {
    setNextValue(e.target.value)
  }

  const isDateDifferent = (from, to) => {
    return formatDateToTimestamp(from) !== formatDateToTimestamp(to)
  }

  const stringifyDate = (date, previousDate) => {
    const yearMonthDay = date.split('.').reverse().join('/')
    const hours = previousDate.getHours()
    const minutes = previousDate.getMinutes()
    const seconds = previousDate.getSeconds()

    return `${yearMonthDay}, ${hours}:${minutes}:${seconds}`
  }

  const handleBlur = () => {
    const prevValue = new Date(value)
    const nextValueCheck = new Date(stringifyDate(nextValue, prevValue))
    if (!isValidCheck(nextValueCheck)) {
      setNextValue(formatDate(prevValue, 'dd.MM.yyyy'))
      return
    }

    if (!compareToExtremum(nextValueCheck, name)) {
      setNextValue(formatDate(prevValue, 'dd.MM.yyyy'))
      return
    }

    if (isDateDifferent(prevValue, nextValueCheck)) {
      setNextValue(formatDate(nextValueCheck, 'dd.MM.yyyy'))
      onChange(formatDateToTimestamp(nextValueCheck))
    }
  }

  return (
    <input
      type='tel'
      pattern='[0-9.]'
      value={nextValue}
      onChange={e => handleChange(e)}
      onBlur={() => handleBlur()}
      className={styles.smartInput}
    />
  )
}
