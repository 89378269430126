import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { Logo } from 'src/components/Logo'
import {
  getLongTime,
  getUTCDate,
  formatDate,
} from 'src/utils/time/moment.helper'
import { getPeriodByEntity, getDependsOnByEntity } from 'src/redux/selectors'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const PERIODS = {
  keysMirror: {
    '1h': '1h',
    '24h': '24h',
    '7d': '7d',
    '30d': '30d',
    custom: 'custom',
  },
  names: {
    '1h': intl.t('components.shareMapHeader.periods.names.1h'),
    '24h': intl.t('components.shareMapHeader.periods.names.24h'),
    '7d': intl.t('components.shareMapHeader.periods.names.7d'),
    '30d': intl.t('components.shareMapHeader.periods.names.30d'),
    custom: intl.t('components.shareMapHeader.periods.names.custom'),
  },
}

export const BLOCK_BASES = {
  marketCap: intl.t('components.shareMapHeader.blockBases.marketCap'),
  volume: intl.t('components.shareMapHeader.blockBases.volume'),
  volumeReported: intl.t('components.shareMapHeader.blockBases.volume'),
}

export const ShareMapHeader = () => {
  const router = useRouter()
  const SLICES = {
    all: router.asPath.includes('exchange')
      ? 'Exchanges'
      : router.asPath.includes('nft')
      ? 'NFTs'
      : 'Coins and tokens',
    coins: 'Coins',
    tokens: 'Tokens',
    nft: 'NFTs',
  }
  const isNft = router.asPath.includes('nft')
  const period = useSelector(getPeriodByEntity)
  const dependsOn = useSelector(getDependsOnByEntity)
  const activeSliceKey = router.query?.display || 'all'
  const activeSliceLabel = SLICES[activeSliceKey]
  const periodNft =
    isNft && router.query.period ? PERIODS.names[router.query.period] : null
  const {
    active: activePeriodKey,
    start: activePeriodStart,
    end: activePeriodEnd,
  } = period
  const dataTypeLabel = PERIODS.names[activePeriodKey]
  const activeBlocksBaseLabel = BLOCK_BASES[isNft ? 'volume' : dependsOn]

  return (
    <div className={styles.mapHeader}>
      <div className={styles.left}>
        <Logo />
      </div>
      <div className={styles.right}>
        <div className={styles.slice}>{activeSliceLabel}</div>
        <span className={styles.dot}>•</span>
        <div className={styles.period}>
          {periodNft || dataTypeLabel}
          {activePeriodKey == PERIODS.keysMirror['custom']
            ? ` ${formatDate(
                getUTCDate(activePeriodStart),
                'dd.MM.yyyy'
              )}-${formatDate(getUTCDate(activePeriodEnd), 'dd.MM.yyyy')}`
            : null}
        </div>
        <span className={styles.dot}>•</span>
        <div className={styles.base}>{activeBlocksBaseLabel}</div>
        <span className={styles.dot}>•</span>
        <div className={styles.time}>{getLongTime()}</div>
      </div>
    </div>
  )
}

export default ShareMapHeader
