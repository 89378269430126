import { useState } from 'react'
import { Dropdown } from 'src/components/Dropdown'
import { Search } from './components'
import { Exceptions } from './components/Search/Exceptions'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const HideEntityFilter = ({
  entity,
  mapDataArray,
  addItem,
  deleteItem,
  reset,
  currentItemsDict = [],
}) => {
  const [searchValue, setSearchValue] = useState('')

  const onSearchValueChange = event => setSearchValue(event.target.value)
  const onAddItem = symbol => addItem(symbol)
  const onClearAll = () => reset()

  const num = Object.keys(currentItemsDict).filter(i => i).length
  const exceptPickedItems = mapDataArray.filter(l => !currentItemsDict[l.slug])

  return (
    <Dropdown
      title={
        <>
          <span className={styles.title}>Hide </span>
          {num > 0 && <span className={styles.active}>({num})</span>}
        </>
      }
      showArrow
    >
      <div className={clsx(styles.multipleEntitySelect, styles.entitySelect)}>
        <Search
          reset={onClearAll}
          selectedBlock={
            <Exceptions
              currentItemsDict={currentItemsDict}
              deleteItem={deleteItem}
              data={mapDataArray}
              entity={entity}
            />
          }
          placeholder='Search'
          list={exceptPickedItems}
          searchValue={searchValue}
          onSearchChange={onSearchValueChange}
          onSelectItem={onAddItem}
          entity={entity}
          symbolAction='-'
        />
      </div>
    </Dropdown>
  )
}

export default HideEntityFilter
