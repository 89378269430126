import { useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { CustomDropdown } from 'src/components/CustomDropdown'
import { CustomDate } from './components/CustomDate'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const PeriodFilter = ({
  title,
  action,
  options,
  value,
  periods,
  valueFormatter,
  defaultValue = '',
}) => {
  const [formVisabilty, setFormVisabilty] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  let titleValue = valueFormatter ? valueFormatter(value, options) : value

  if (formVisabilty) {
    titleValue = intl.t('components.periodFilter.custom')
  }

  const onCustomItemSelect = () => {
    setFormVisabilty(true)
  }

  const onChange = v => {
    setFormVisabilty(false)
    setIsOpen(false)
    action && action({ ...periods, active: v })
  }

  const customPeriodsChange = newPeriods => {
    setFormVisabilty(false)
    action && action({ ...newPeriods, active: 'custom' })
  }

  const changeOpen = val => setIsOpen(val)

  return (
    <CustomDropdown
      title={title}
      titleValue={titleValue}
      showArrow
      isOpen={isOpen}
      setIsOpen={changeOpen}
      wrapperClassName={clsx({ [styles.green]: value !== defaultValue })}
    >
      {formVisabilty ? (
        <CustomDate
          periods={periods}
          onClose={changeOpen}
          onChange={newPeriods => customPeriodsChange(newPeriods)}
        />
      ) : (
        options.map(option => {
          const isChecked = option.value === value
          if (option.value === 'custom') {
            return (
              <div
                key='option-custom'
                onClick={onCustomItemSelect}
                className={styles.item}
              >
                {isChecked && (
                  <Image
                    src='/assets/icons/icon-check.svg'
                    width='16px'
                    height='16px'
                    alt='Check icon'
                  />
                )}
                {intl.t('components.periodFilter.selectCustom')}
              </div>
            )
          }
          return (
            <div
              key={`option-${option.value}`}
              onClick={() => onChange(option.value)}
              className={styles.item}
            >
              {isChecked && (
                <Image
                  src='/assets/icons/icon-check.svg'
                  width='16px'
                  height='16px'
                  alt='Check icon'
                />
              )}
              {option.label}
            </div>
          )
        })
      )}
    </CustomDropdown>
  )
}

export default PeriodFilter
