import React from 'react'
import clsx from 'clsx'
import { Icon } from 'src/components/Icon'
import { ENTITY } from 'src/consts'
import StarImg from 'public/assets/icons/icon-star.svg'
import styles from './styles.module.scss'

type HeaderRowProps = {
  className?: string
  data: any
  inWatchList: boolean
  needStar: boolean
  onAddToWatchlist?: () => void
  onRemoveFromWatchlist?: () => void
  valueSearch: string
}

export const HeaderRow = ({
  valueSearch,
  inWatchList,
  onAddToWatchlist,
  onRemoveFromWatchlist,
  needStar,
  className,
  data,
}: HeaderRowProps) => {
  const handleWatchlistClick = e => {
    e.preventDefault()
    e.stopPropagation()

    if (inWatchList && onRemoveFromWatchlist) {
      onRemoveFromWatchlist()
    } else if (!inWatchList && onAddToWatchlist) {
      onAddToWatchlist()
    }
  }

  const getTitle = () => {
    const { name = '' } = data

    const nameLower = data.name.toLowerCase()
    const search = valueSearch.toLowerCase()

    if (!valueSearch) return name
    const index = nameLower.indexOf(search)
    if (index === -1) return name

    const prev = name.substring(0, index)
    const underline = name.substring(index, index + search.length)
    const next = name.substring(index + search.length)

    return (
      <>
        {prev}
        <span className={styles.underline}>{underline}</span>
        {next}
      </>
    )
  }

  const itemEntity = data.type === 'coin' ? ENTITY.COIN : ENTITY.EXCHANGE

  return (
    <div className={clsx(styles.row, className)}>
      <div className={styles.titleBox}>
        <Icon className={styles.icon} data={data} entity={itemEntity} />
        <div className={styles.title}>{getTitle()}</div>
      </div>
      {data.symbol && (
        <div className={styles.rightBox}>
          {itemEntity !== ENTITY.EXCHANGE && (
            <div className={styles.symbol}>{data.symbol}</div>
          )}
          {needStar && (
            <StarImg
              onClick={handleWatchlistClick}
              className={clsx(styles.star, {
                [styles.starActive]: inWatchList,
              })}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default HeaderRow
