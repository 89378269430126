import { API_GET_GLOBAL_STATS, API_GLOBAL_SEARCH } from 'src/consts'
import { API_ENDPOINT } from 'src/consts/env'

export const getGlobalState = async () => {
  return fetch(`${API_ENDPOINT}${API_GET_GLOBAL_STATS}`).then(res => res.json())
}

export const performGlobalSearch = async (query, ...entities) => {
  if (!query) return

  const params = [`q=${query}`]
  if (entities && entities.length > 0)
    params.push(`entity=${entities.join(',')}`)

  const url = `${API_ENDPOINT}${API_GLOBAL_SEARCH}?${params.join('&')}`
  return fetch(url).then(res => res.json())
}
