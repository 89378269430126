import { intl } from 'src/i18n'

export const getDifference = dateStr => {
  const seconds = getDiffSeconds(dateStr)
  return formatPeriodAgo(seconds)
}

export const getDiffSeconds = dateStr => {
  const currentDate = new Date()
  const gmtOffsetMs = currentDate.getTimezoneOffset() * 60 * 1000
  const timestampDifference =
    currentDate.getTime() - new Date(dateStr).getTime() + gmtOffsetMs
  return timestampDifference / 1000
}

export const formatPeriodAgo = seconds => {
  const minutesDifference = Math.floor(seconds / 60)
  const hoursDifference = Math.floor(minutesDifference / 60)

  if (hoursDifference < 1) {
    if (minutesDifference <= 10) {
      return intl.t('utils.justNow')
    }
    return intl.t('utils.1hAgo')
  } else if (hoursDifference < 24) {
    return intl.t('utils.hoursAgo', { hoursDifference })
  } else {
    const days = `${Math.floor(hoursDifference / 24)}`
    const oneRegEx = /1$/
    const elevenRegEx = /11$/
    if (oneRegEx.test(days) && !elevenRegEx.test(days)) {
      return intl.t('utils.dayAgo', { days })
    }
    return intl.t('utils.daysAgo', { days })
  }
}
