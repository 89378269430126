import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useAppIntl } from 'src/i18n'
import { SearchBox } from 'src/components/SearchBox'
import { addWatchListItem, deleteWatchListItem } from 'src/redux/thunks'
import { getActiveWatchlist } from 'src/redux/selectors'
import { performGlobalSearch } from 'src/services/globalServices'
import { combinedAnalytics } from 'src/utils/common'
import Row from './Row'
import styles from './styles.module.scss'
import clsx from 'clsx'

export const GlobalSearch = ({ className }) => {
  const intl = useAppIntl()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState([])
  const router = useRouter()
  const activeTab = useSelector(getActiveWatchlist)
  const [searchTimer, setSearchTimer] = useState(null)

  const onChangeSearch = q => {
    setSearch(q)

    // Push the search query to the dataLayer when a search is initiated
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: 'searchPerformed',
        searchQuery: q, // Push the search query to the dataLayer
      })
    }

    if (!!searchTimer) {
      clearTimeout(searchTimer)
    }

    const timer = setTimeout(
      () => performGlobalSearch(q).then(options => setOptions(options)),
      1000
    )

    setSearchTimer(timer)
  }

  const onSelect = item => {
    const path = `/${item.type}/${item.slug}`
    router.push(path)

    // Track the search selection event
    combinedAnalytics('Search', 'Search', 'Added', path)
  }

  const handleWatchlistCoin = (slug, action) => {
    action === 'add'
      ? dispatch(addWatchListItem(slug))
      : dispatch(deleteWatchListItem(slug))
  }

  const rowRenderer = item => {
    const inWatchList = activeTab ? activeTab.data.includes(item.slug) : false
    return (
      <Row
        data={item}
        inWatchList={inWatchList}
        needStar={item.type === 'coin'}
        onAddToWatchlist={() => handleWatchlistCoin(item.slug, 'add')}
        onRemoveFromWatchlist={() => handleWatchlistCoin(item.slug, 'remove')}
        valueSearch={item.valueSearch}
      />
    )
  }

  return (
    <SearchBox
      className={clsx(styles.globalSearch, className, 'unselectable')}
      onSelect={onSelect}
      rowRenderer={rowRenderer}
      rowHeight={48}
      options={options}
      onChangeSearch={onChangeSearch}
      placeholder={intl.t('globalSearch.placeholder')}
      search={search}
    />
  )
}

export default GlobalSearch
