import { memo, useState } from 'react'
import AdButlerPlacement from '../AdButlerPlacement'
import styles from './styles.module.scss'

export const TextLinkInBottomLine = memo(() => {
  const [shown, setShown] = useState(true)

  const onEmpty = () => setShown(false)

  return (
    shown && (
      <div className={styles.textLinkInBottomLine}>
        <AdButlerPlacement
          className={styles.place}
          place={'494699'}
          forceProxy={true}
          onEmpty={onEmpty}
        />
      </div>
    )
  )
})
