import { ReactNode, useState } from 'react'
import { List, AutoSizer } from 'react-virtualized'
import clsx from 'clsx'
import Image from 'next/image'
import styles from './styles.module.scss'

type Props = {
  icon?: boolean
  onChangeSearch: (search: string) => void
  options: any[]
  rowRenderer: (item: any) => ReactNode
  onSelect: (item: any) => void
  placeholder: string
  rowHeight: number
  search: string
}

export const MobileView = ({
  onChangeSearch,
  onSelect,
  options,
  placeholder,
  rowHeight = 48,
  rowRenderer,
  search,
}: Props) => {
  const [listVisible, setListVisible] = useState(false)

  const toggleOpen = () => setListVisible(true)

  const onClear = () => onChangeSearch('')

  const handleFocus = () => setListVisible(true)

  const onClickClose = () => setListVisible(false)

  const onClickOverlay = () => {
    onChangeSearch('')
    setListVisible(false)
  }

  const renderNoOptions = () => null

  const handleSelect = selectedValue => {
    onChangeSearch('')
    setListVisible(false)
    onSelect(selectedValue)
  }

  const handleSearch = event => onChangeSearch(event.target.value)

  const handleRowRenderer = ({ key, index, style }) => {
    const currentData = { ...options[index], valueSearch: search }

    return (
      <div
        key={key}
        style={style}
        onClick={() => handleSelect(currentData)}
        className={clsx('ListItemWrapper')}
      >
        {rowRenderer(currentData)}
      </div>
    )
  }

  const renderList = () => {
    if (!listVisible) return null
    if (!options?.length) return renderNoOptions()

    return (
      <>
        <div className={styles.overlay} onClick={onClickOverlay} />
        <div className={styles.listWrap}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={options.length}
                rowHeight={rowHeight}
                rowRenderer={handleRowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
          <div className={styles.listCloseIcon} onClick={onClickClose}>
            <Image
              alt='Close search'
              layout='fixed'
              width='16px'
              height='16px'
              src='/assets/icons/icon-close-cross.svg'
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={clsx(styles.searchBox, 'unselectable')}>
      <button className={styles.search} onClick={toggleOpen}>
        <Image
          className={styles.icon}
          src='/assets/icons/icon-search-grey.svg'
          width='24px'
          height='24px'
          alt='Search icon'
        />
      </button>
      {listVisible && (
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <Image
              className={styles.icon}
              src='/assets/icons/icon-search-grey.svg'
              width='24px'
              height='24px'
              alt='Search icon'
              layout='fixed'
            />
            <input
              className={styles.input}
              onFocus={handleFocus}
              onChange={handleSearch}
              placeholder={placeholder}
              value={search}
            />
            <div className={styles.buttonContainer}>
              {search && (
                <div className={styles.closeContainer}>
                  <Image
                    className={styles.closeIcon}
                    src='/assets/icons/icon-close-cross.svg'
                    width='16px'
                    height='16px'
                    alt='Search icon'
                    onClick={onClear}
                  />
                </div>
              )}
              <button className={styles.cancel} onClick={onClickClose}>
                Cancel
              </button>
            </div>
          </div>
          {renderList()}
        </div>
      )}
    </div>
  )
}

export default MobileView
