export const selectStyles = {
  option: (base, state) => ({
    ...base,
    position: 'relative',
    color: '#fff',
    fontSize: 14,
    textAlign: 'left',
    cursor: 'pointer',
    backgroundColor: '#3c3d3d',
    padding: '8px 32px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'hsla(0,0%,100%,.05)',
    },
    ...(state.isSelected && {
      '&:before': {
        content: "url('/assets/icons/icon-check.svg')",
        display: 'block',
        width: '16px',
        height: '16px',
        position: 'absolute',
        left: '8px',
      },
    }),
  }),
  container: base => ({
    ...base,
    width: '100%',
    position: 'static',
  }),
  control: base => ({
    ...base,
    fontSize: 14,
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    background: 'none',
    position: 'static',
    border: 0,
    boxShadow: 'none',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    paddingLeft: 4,
    display: 'flex',
    height: '32px',
    alignItems: 'center',
    color: '#fff',
  }),
  placeholder: base => ({
    ...base,
    color: '#fff',
    fontSize: 14,
  }),
  input: () => ({
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    marginLeft: '10px',
  }),
  menuList: base => ({
    ...base,
    backgroundColor: '#3c3d3d',
    height: 'inherit',
  }),
  menu: base => ({
    ...base,
    margin: '4px 0 0',
    left: 0,
    backgroundColor: '#3c3d3d',
    borderRadius: '2px',
    overflow: 'hidden',
    minWidth: '170px',
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
  }),
}
