import { useRef } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import clsx from 'clsx'
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'
import { ScrollToTop } from 'src/components/ScrollToTop'
import { NewsFeed } from 'src/components/NewsFeed'
import { MAIN_SITE_IMAGE, WEBSITE, SITENAME } from 'src/consts'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'
import useWidth from 'src/hooks/useWidth'

// this class is used to make a screenshot on /api/share
const SCREENSHOT_CLASS = 'MapPage'

export const MainLayout = ({
  children,
  title = intl.t('layouts.mainLayout.title'),
  description = intl.t('layouts.mainLayout.description'),
  uri = '',
  pageMeta = false,
  isHeatmap = false,
  fixedHeader = false,
  scrollToTop = false,
  switcherWatchlist = false,
  newsFeed = false,
  newsFeedData = false,
  omitDefaultImage = false,
  noIndex = false, // New prop to control indexing
}) => {
  const router = useRouter()
  const routesWithoutFooter = [
    '/',
    '/exchange',
    '/watchlist',
    '/watchlist/table',
  ]
  const appRef = useRef()
  const isShare = router.asPath.includes('getScreen')
  const { isMobile } = useWidth()

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel='shortcut icon' href={'/favicon.ico'} type='image/x-icon' />
        <link rel='icon' href={'/favicon.ico'} type='image/x-icon' />
        <meta name='description' content={description} />
        {!omitDefaultImage && (
          <meta name='image' content={`${MAIN_SITE_IMAGE}`} />
        )}
        <meta property='og:title' content={title} />
        <meta property='og:url' content={`${WEBSITE}${uri}`} />
        {!omitDefaultImage && (
          <>
            <meta property='og:image' content={`${MAIN_SITE_IMAGE}`} />
            <meta property='og:image:type' content='image/png' />
            <meta property='og:image:width' content='600' />
            <meta property='og:image:height' content='315' />
          </>
        )}
        <meta property='og:site_name' content='COIN360' />
        <meta property='og:description' content={description} />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:site' content='@COIN360com' />
        <meta name='twitter:description' content={description} />
        {!omitDefaultImage && (
          <meta name='twitter:image' content={`${MAIN_SITE_IMAGE}`} />
        )}
        {noIndex && <meta name='robots' content='noindex, nofollow' />}
        {pageMeta || (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org/',
                '@type': 'WebPage',
                author: {
                  '@type': 'Organization',
                  address: {
                    '@type': 'PostalAddress',
                    addressCountry: 'Malta',
                    addressLocality: 'Valetta',
                    addressRegion: 'VLT',
                    postalCode: '1455',
                    streetAddress: 'Old Bakery Street 171',
                  },
                  foundingDate: '2017',
                  legalName: 'COIN360 LTD',
                },
                name: title,
                description: description,
                url: `${WEBSITE}${uri}`,
                image: MAIN_SITE_IMAGE,
                sameAs: [
                  'https://www.reddit.com/r/Coin360',
                  'https://twitter.com/coin360com',
                  'https://www.facebook.com/Coin360com/',
                  'https://t.me/COIN360com',
                  'https://mt.linkedin.com/company/coin360',
                  'https://www.tiktok.com/@coin360com?lang=en',
                  'https://www.youtube.com/@COIN-li4tr/about',
                ],
              }),
            }}
          />
        )}
        <link rel='canonical' href={`${WEBSITE}${uri}`} />
      </Head>
      <div className={clsx(styles.app, { [styles.heatmap]: isHeatmap })}>
        {!isShare && (
          <Header fixed={fixedHeader} switcherWatchlist={switcherWatchlist} />
        )}
        <section
          className={clsx(styles.content, SCREENSHOT_CLASS)}
          ref={appRef}
        >
          {children}
          {!routesWithoutFooter.includes(router.route) && !isShare && (
            <Footer />
          )}
        </section>
        {/* {newsFeed && !isShare && <NewsFeed newsFeedData={newsFeedData} />}
        {scrollToTop && <ScrollToTop ref={appRef} overflow />} */}
      </div>
    </>
  )
}
