import styles from './styles.module.scss'
import clsx from 'clsx'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import Link from 'next/link'

const WidgetLogo = dynamic(() => import('./WidgetLogo'), { ssr: false })

export const Logo = ({ className = undefined, widget }) => {
  if (widget) return <WidgetLogo className={className} widget={widget} />

  return (
    <Link href='/' prefetch={false}>
      <a className={clsx(styles.logo, className)} rel='noopener'>
        <Image
          className={clsx(styles.boxText, widget)}
          alt='cryptocurrency widget, price, heatmap'
          src='/assets/logo.svg'
          width='124px'
          height='18px'
          layout='fixed'
        />
      </a>
    </Link>
  )
}

export default Logo
