import styles from './styles.module.scss'
import Image from 'next/image'
import { useState, useRef } from 'react'
import { useWatchlist } from '../UseWatchlist'
import Tabs from 'src/pages/Watchlist/components/Tabs'
import useOnClickOutside from 'use-onclickoutside'

export const MobileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { tabActive, tabs, addTab } = useWatchlist()
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  const closeDropdown = () => {
    setIsOpen(false)
  }

  if (tabs?.length === 0 && !addTab) {
    return <></>
  }

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        <Image
          className={styles.icon}
          alt='arrow'
          src={
            isOpen
              ? '/assets/icons/icon-arrow-up.svg'
              : '/assets/icons/icon-arrow-down.svg'
          }
          width='12px'
          height='7px'
        />
      </div>
      {isOpen && (
        <>
          <div className={styles.overlay} onClick={closeDropdown}></div>
          <div className={styles.dropdownMenu}>
            <Tabs />
          </div>
        </>
      )}
    </div>
  )
}
