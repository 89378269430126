import { API_NFT_BLOCKCHAINS, API_NFT_COLLECTIONS } from 'src/consts'
import { NFTCollection } from 'src/models'
import http from 'src/services/http'
import {
  decorateRequestUrl,
  exceptionsNFTData,
  filterNFTData,
  transformObjectKeys,
} from 'src/utils'

export type GetNFTCollectionParams = {
  limit?: number
  period?: string
  offset?: number
  volumeMin?: number
  volumeMax?: number
  /** MarketCap */
  mcMin?: number
  /** MarketCap */
  mcMax?: number
  /** Floor price */
  fpMin?: number
  /** Floor price */
  fpMax?: number
  ownersMin?: number
  ownersMax?: number
  itemsMin?: number
  itemsMax?: number
  blockchain?: string
}

export type GetNFTCollectionAssetsParams = {
  address: string
  blockchain: string
  page: number
  sort__field?: string
  sort__order?: string
}

export type MinMaxNFTCollections = {
  marketCap: number
  volume: number
}

export type TotalValuesNFT = {
  marketCap: number
  count: number
}

export type NFTCollections = {
  data: NFTCollection[]
  max: MinMaxNFTCollections
  min: MinMaxNFTCollections
  total: TotalValuesNFT
  exceptions: string[]
}

const keyConfig: PartialRecord<keyof GetNFTCollectionParams, string> = {
  volumeMin: 'filter__volume__min',
  volumeMax: 'filter__volume__max',
  mcMin: 'filter__market_cap__min',
  mcMax: 'filter__market_cap__max',
  fpMin: 'filter__floor_price__min',
  fpMax: 'filter__floor_price__max',
  ownersMin: 'filter__number_of_owners__min',
  ownersMax: 'filter__number_of_owners__max',
  itemsMin: 'filter__number_of_items__min',
  itemsMax: 'filter__number_of_items__max',
}

export function getNFTCollections(
  config: GetNFTCollectionParams
): Promise<NFTCollections> {
  const params = transformObjectKeys(config, keyConfig)
  if (!!params['limit'] && params['limit'] == 'all') {
    delete params['limit']
  }
  if (!!params['blockchain'] && params['blockchain'] == 'all') {
    delete params['blockchain']
  }
  const url = decorateRequestUrl(API_NFT_COLLECTIONS)

  return http
    .get({
      url,
      params,
    })
    .then(res => {
      let data = res.data
      const exceptions = exceptionsNFTData(data, params)

      data = filterNFTData(data, params)

      return {
        data,
        max: res.maxValues,
        min: {
          marketCap: 0,
          volume: 0,
        },
        total: res.total,
        exceptions: exceptions,
      }
    })
}

export type getNFTCollectionParams = {
  address: string
}

export function getNFTCollection(
  params: getNFTCollectionParams
): Promise<NFTCollection> {
  const url = decorateRequestUrl(API_NFT_COLLECTIONS)
  return http.get({
    url: `${url}/${params.address}`,
  })
}

export function getNFTCollectionAssets(config: GetNFTCollectionAssetsParams) {
  const url = decorateRequestUrl(API_NFT_COLLECTIONS)
  const { address, blockchain, page, sort__field, sort__order } = config

  return http.get({
    url: `${url}/${blockchain}:${address}/assets/${page}`,
    params: {
      sort__field,
      sort__order,
    },
  })
}

export function getNFTBlockchains() {
  const url = decorateRequestUrl(API_NFT_BLOCKCHAINS)
  return http.get({
    url: `${url}`,
  })
}

export type getNFTCollectionsSearchParams = {
  filter__name_search: string
}

export function getNFTCollectionsSearch(
  config: getNFTCollectionsSearchParams
): Promise<NFTCollections> {
  const params = transformObjectKeys(config, keyConfig)

  const url = decorateRequestUrl(API_NFT_COLLECTIONS)

  return http.get({
    url,
    params,
  })
}
