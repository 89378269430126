import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { DrawerRow } from './DrawerRow'
import { ENTITY } from 'src/consts'
import { intl } from 'src/i18n'

const contains = (a, b) => {
  return String(a).toLowerCase().indexOf(String(b).toLowerCase()) >= 0
}

export const Search = ({
  apply,
  reset,
  selectedBlock,
  pictogram,
  searchValue,
  list,
  placeholder,
  className,
  onSearchChange,
  onSelectItem,
  entity,
  symbolAction,
}) => {
  const filtered = list.filter(i => {
    return contains(i.name, searchValue) || contains(i.slug, searchValue)
  })

  return (
    <div className={clsx(className)}>
      <div className={styles.inputBlock}>
        {pictogram}
        <input
          placeholder={placeholder}
          value={searchValue}
          onChange={onSearchChange}
          ref={r => r && r.focus()}
        />
        {reset && (
          <button className={styles.reset} onClick={reset}>
            {intl.t('components.hideEntityFilter.components.search.reset')}
          </button>
        )}
        {apply && (
          <button className={styles.apply} onClick={apply}>
            {intl.t('components.hideEntityFilter.components.search.apply')}
          </button>
        )}
      </div>
      {selectedBlock}
      <div className={styles.suggestions}>
        {filtered.length > 0 ? (
          filtered.slice(0, 100).map(item => {
            return (
              <div
                key={item.slug}
                onClick={() => {
                  onSelectItem && onSelectItem(item.slug)
                }}
              >
                <DrawerRow
                  data={{ ...item, valueSearch: searchValue }}
                  entity={entity}
                  symbolAction={symbolAction}
                  withoutTitle={entity === ENTITY.EXCHANGE}
                />
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
