import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { getDifference } from 'src/utils/time'
import { getLineNewsSelector } from 'src/redux/selectors'
import { getLineNews } from 'src/redux/thunks'
import styles from './styles.module.scss'
import { NewsFeedGlobals } from '../NewsFeedGlobals'
import { TextLinkInBottomLine } from 'src/components/Banners'
import { intl } from 'src/i18n'

export const NewsTicker = ({
  globals,
  currency,
  periodsActive,
  handleNewsInteraction,
}) => {
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [translateTime, setTranslateTime] = useState(200)
  const data = useSelector(getLineNewsSelector)
  const dispatch = useDispatch()
  const wrapper = useRef()

  useEffect(() => {
    if (data.length === 0 && isFirstTime) {
      dispatch(getLineNews())
    } else {
      startMovement()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const startMovement = () => {
    if (isFirstTime && data.length > 0) {
      const scrollWidth = wrapper.current ? wrapper.current.scrollWidth : 0
      const speed =
        scrollWidth > window.innerWidth
          ? (scrollWidth + window.innerWidth) / 70
          : scrollWidth / 70

      setIsFirstTime(false)
      setTranslateTime(Math.ceil(speed))

      document.head.insertAdjacentHTML(
        'beforeend',
        `<style type="text/css" id="marquee-styles">

          .marquee {
            opacity: 0;
            animation: marqueeInfinite linear infinite;
          }

          .marquee:hover {
            animation-play-state: paused;
          }

          @keyframes marqueeInfinite {
            0% {
              transform: translate3d(0, 0, 0);
              opacity: 0;
            }

            0.5%{
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }

            1%{
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }

            100% {
              transform: translate3d(-${scrollWidth}px, 0, 0);
              opacity: 1;
            }
          }

        </style>`
      )
    }
  }

  const globalComponent = (
    <NewsFeedGlobals
      globals={globals}
      currency={currency}
      period={periodsActive}
    />
  )
  return data.length > 0 ? (
    <div
      ref={wrapper}
      style={{ animationDuration: `${translateTime}s` }}
      className={clsx(styles.wrapper, 'marquee')}
    >
      {globalComponent}
      {data.map((item, index) => {
        const date = getDifference(item.date)
        return index % 6 === 0 ? (
          <TextLinkInBottomLine key={`${item.src}${index}`} width={314} />
        ) : (
          <div className={styles.newsItem} key={`${item.src}${index}`}>
            <a
              href={item.link}
              target='_blank'
              rel={`noopener noreferrer${
                item.src !== 'cryptosrus' ? ' nofollow' : ''
              }`}
              className={styles.ctNews}
              onClick={() => handleNewsInteraction(item.link)}
            >
              <div>{item.title}</div>
              <div className={styles.delimiter} />
              <div className={styles.author}>{item.name}</div>
              <div className={styles.delimiter} />
              <div className={styles.date}>{date}</div>
            </a>
          </div>
        )
      })}
      {globalComponent}
    </div>
  ) : (
    <div className={styles.letterHolder}>
      {intl
        .t('components.newsFeed.components.newsTicker.loading')
        .split('')
        .map((c, i) => {
          return (
            <div key={i} className={clsx(styles[`l-${i + 1}`], styles.letter)}>
              {c}
            </div>
          )
        })}
    </div>
  )
}

export default NewsTicker
