import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Link from 'next/link'
import queryString from 'query-string'
import { useRouter } from 'next/router'

type ItemProps = {
  item: any
}

export const Item = ({ item }: ItemProps) => {
  const router = useRouter()
  const currentLocationPath = router.pathname.split('/')[1]
  const getUrl = item => {
    const { path, withQueryParams } = item
    const query = router.query

    if (!router.pathname.split('/').find(e => e === '[slug]')) {
      if (router.pathname.includes(path)) {
        const searchString = queryString.stringify(query)
        if (!query) return path
        return withQueryParams ? `${path}?${searchString}` : path
      } else if (query['currency']) {
        const currency = { currency: query['currency'] }
        const searchString = queryString.stringify(currency)
        return withQueryParams ? `${path}?${searchString}` : path
      }
    }

    return path
  }

  const handleWatchlistNavigation = e => {
    e.preventDefault() // Prevent default link behavior
    window.location.href = getUrl(item) // Force reload to the item's URL
  }

  const betaTag = item.beta ? <span className={styles.betaTag}>NEW</span> : null // Removed the beta tag

  const { type } = item

  const isCurrentPage = item.key.find(key => key === currentLocationPath)

  //This is a temporary solution to handle the watchlist item, remove this part we start working with landing page for watchlists
  const isWatchlistItem = item.path === '/watchlist'

  if (type === 'menuItem') {
    if (isWatchlistItem) {
      // Special handling for the watchlist item
      return (
        <a
          href={getUrl(item)} // Ensure it still has a valid href for accessibility reasons
          onClick={handleWatchlistNavigation}
          className={clsx(styles.dropdownParent, {
            [styles.dropdownParentActive]: isCurrentPage,
          })}
        >
          <div className={styles.menuItemContainer}>
            {item.title}
            {betaTag}
          </div>
        </a>
      )
    } else {
      // Normal handling for other items
      return (
        <Link href={getUrl(item)}>
          <a
            className={clsx(styles.dropdownParent, {
              [styles.dropdownParentActive]: isCurrentPage,
            })}
          >
            <div className={styles.menuItemContainer}>
              {item.title}
              {betaTag}
            </div>
          </a>
        </Link>
      )
    }
  }

  return (
    <div className={styles.dropdown}>
      <span
        className={clsx(styles.dropdownParent, {
          [styles.dropdownParentActive]:
            (currentLocationPath === '' && item.key[0] === '/') ||
            isCurrentPage,
        })}
      >
        {!!item.path ? (
          <Link href={getUrl(item)} key={item.title}>
            <a>{item.title}</a>
          </Link>
        ) : (
          item.title
        )}
      </span>
      <div className={styles.dropdownContent}>
        {item.subMenu.map(item => {
          if (item.type === 'separator') {
            return <div key={item.key} className={styles.separator} />
          }

          return (
            <Link href={getUrl(item)} key={item.title}>
              <a className={styles.dropdownItem}>{item.title}</a>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
