import { NEWS_ACTIVE, NFT_ACTIVE } from 'src/consts/env'
import { intl } from 'src/i18n'

export const getAllNewMenuItems = () => {
  return [
    // {
    //   key: ['/', 'coin', 'charts'],
    //   title: 'Coins',
    //   path: '/',
    //   withQueryParams: false,
    //   type: 'dropdown',
    //   subMenu: [
    //     {
    //       key: '/',
    //       title: 'Heatmap',
    //       path: '/',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/',
    //       title: 'By Volume',
    //       path: '/?dependsOn=volume',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/',
    //       title: 'Top 50 Gainers',
    //       path: '/?slice=gainers50',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/',
    //       title: 'Top 50 Losers',
    //       path: '/?slice=losers50',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/',
    //       title: 'Market Analytics',
    //       path: '/market-analytics',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/coin',
    //       title: 'Coin Directory',
    //       path: '/coin',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //   ],
    // },
    // {
    //   key: ['nft'],
    //   title: 'NFT',
    //   type: 'dropdown',
    //   path: '/nft',
    //   withQueryParams: false,
    //   subMenu: [
    //     {
    //       key: '/exchange',
    //       title: 'Heatmap',
    //       path: '/nft',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/exchange',
    //       title: 'NFT Directory',
    //       path: '/nft/table',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //   ],
    // },
    // {
    //   key: ['exchange'],
    //   title: 'Exchanges',
    //   type: 'dropdown',
    //   path: '/exchange',
    //   withQueryParams: false,
    //   subMenu: [
    //     {
    //       key: '/exchange',
    //       title: 'Heatmap',
    //       path: '/exchange',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //     {
    //       key: '/exchange',
    //       title: 'Exchange Directory',
    //       path: '/exchange/table',
    //       withQueryParams: true,
    //       type: 'menuItem',
    //     },
    //   ],
    // },
    {
      key: ['news', 'press-releases', 'events', 'newsletter'],
      title: 'News',
      type: 'dropdown',
      path: '/news',
      withQueryParams: false,
      subMenu: [
        {
          key: '/news',
          title: 'News',
          path: '/news',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: '/newsletter',
          title: 'Newsletter',
          path: '/newsletter',
          withQueryParams: false,
          type: 'menuItem',
        },
      ],
    },
    {
      key: [
        'top-list',
        'lists',
        'toplists',
        'best',
        'glossary',
        'learn',
        'review',
      ],
      title: 'Learn',
      type: 'dropdown',
      path: '/learn',
      withQueryParams: false,
      subMenu: [
        {
          key: '/learn',
          key: 'learn',
          title: 'How To',
          path: '/learn',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: '/glossary',
          key: 'glossary',
          title: 'Crypto Glossary',
          path: '/glossary',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: 'list, top-list, toplist',
          title: 'Top Lists',
          path: '/list',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: 'Review, Reviews',
          title: 'Reviews',
          path: '/review',
          withQueryParams: false,
          type: 'menuItem',
        },
      ],
    },
    {
      key: ['academy', 'about'],
      title: 'About',
      type: 'dropdown',
      path: '/about/generalinfo',
      withQueryParams: false,
      subMenu: [
        {
          key: '/about',
          title: 'About Coin360',
          path: '/about/generalinfo',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: '/author',
          title: 'Authors',
          path: '/author',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: '/about/widgets',
          title: 'Widgets',
          path: '/about/widgets',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: '/about/Methodology',
          title: 'Methodology',
          path: '/about/methodology',
          withQueryParams: false,
          type: 'menuItem',
        },
        {
          key: ['/partner', '/partners'],
          title: 'Partners',
          path: '/partner',
          withQueryParams: false,
          type: 'menuItem',
        },
      ],
    },
    {
      key: ['swap'],
      title: 'Swap',
      type: 'menuItem',
      path: '/swap',
      withQueryParams: false,
      beta: true,
    },
  ]
}

export const getAllMenuItems = () => {
  return [
    {
      key: 'coin',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title1'
      ),
      path: '/coin',
      withQueryParams: true,
    },
    {
      key: 'exchange',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title2'
      ),
      path: '/exchange',
    },
    ...(NFT_ACTIVE
      ? [
          {
            key: 'nft',
            title: intl.t(
              'components.header.helpers.menuItems.getAllMenuItems.title3'
            ),
            path: '/nft',
            betaItem: true,
          },
        ]
      : []),
    ...(NEWS_ACTIVE
      ? [
          {
            key: 'news',
            title: intl.t(
              'components.header.helpers.menuItems.getAllMenuItems.title4'
            ),
            path: '/news',
            newItem: true,
          },
        ]
      : []),
    {
      key: 'watchlist',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title5'
      ),
      path: '/watchlist',
    },
    {
      key: 'newsletter',
      title: 'Newsletter',
      path: '/newsletter',
    },
    // {
    //   key: 'liquidity-book',
    //   title: 'Liquidity book',
    //   path: '/liquidity-book',
    //   onlyMobile: true
    // },
    {
      key: 'charts',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title6'
      ),
      path: '/charts',
      onlyMobile: true,
    },
    {
      key: 'glossary',
      path: '/glossary',
      title: 'Glossary',
      onlyMobile: true,
    },
    {
      key: 'press-releases',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title7'
      ),
      path: '/press-releases',
      onlyMobile: true,
    },
    {
      key: 'events',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title8'
      ),
      path: '/events',
      onlyMobile: true,
    },
    {
      description: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.text1'
      ),
      key: 'faq',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title9'
      ),
      path: '/faq',
      onlyMobile: true,
    },
    {
      key: 'about',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title10'
      ),
      path: '/about/generalinfo',
      onlyMobile: true,
      children: [
        {
          key: 'generalInfo',
          path: '/about/generalinfo',
          text: intl.t(
            'components.header.helpers.menuItems.getAllMenuItems.text2'
          ),
          breakAfter: false,
        },
        {
          key: 'methodology',
          path: '/about/methodology',
          text: intl.t(
            'components.header.helpers.menuItems.getAllMenuItems.text4'
          ),
          breakAfter: false,
        },
        // {
        //   key: 'liquidityBook',
        //   path: '/about/liquidity-book',
        //   text: 'Liquidity Book',
        //   breakAfter: true
        // },
        {
          key: 'authors',
          path: '/author',
          text: 'Authors',
          breakAfter: false,
        },
        {
          key: 'widgets',
          path: '/about/widgets',
          text: intl.t(
            'components.header.helpers.menuItems.getAllMenuItems.text6'
          ),
          notMobile: true,
          breakAfter: false,
        },
        {
          key: ['partners'],
          path: '/partner',
          text: 'Partners',
          breakAfter: true,
        },
        {
          key: 'privacyPolicy',
          path: '/about/privacy',
          text: intl.t(
            'components.header.helpers.menuItems.getAllMenuItems.text7'
          ),
          breakAfter: false,
        },
      ],
    },
    {
      key: 'advertising',
      title: intl.t(
        'components.header.helpers.menuItems.getAllMenuItems.title11'
      ),
      path: '/advertising',
      highlighted: true,
    },
  ]
}

const faqConfig = {
  key: 'faq',
  path: '/faq',
  title: intl.t('components.header.helpers.menuItems.faqConfig.title'),
  description: intl.t(
    'components.header.helpers.menuItems.faqConfig.description'
  ),
  children: [
    {
      text: intl.t('components.header.helpers.menuItems.faqConfig.text1'),
      key: 'cryptocurrency-market-capitalization',
      children: [
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text2'),
          key: 'what-is-market-capitalization',
          path: '/faq/cryptocurrency-market-capitalization/what-is-market-capitalization',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header1'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text3'),
          key: 'how-to-calculate-cryptocurrency-market-cap',
          path: '/faq/cryptocurrency-market-capitalization/how-to-calculate-cryptocurrency-market-cap',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header1'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text4'),
          key: 'what-is-total-crypto-market-cap',
          path: '/faq/cryptocurrency-market-capitalization/what-is-total-crypto-market-cap',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header1'
          ),
        },
      ],
    },
    {
      text: intl.t('components.header.helpers.menuItems.faqConfig.text5'),
      key: 'prices-and-volumes',
      children: [
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text6'),
          key: 'how-is-the-price-of-a-cryptocurrency-calculated',
          path: '/faq/prices-and-volumes/how-is-the-price-of-a-cryptocurrency-calculated',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header2'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text7'),
          key: 'what-are-base-and-quote-currencies',
          path: '/faq/prices-and-volumes/what-are-base-and-quote-currencies',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header2'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text8'),
          key: 'what-is-volume-in-cryptocurrency',
          path: '/faq/prices-and-volumes/what-is-volume-in-cryptocurrency',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header2'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text9'),
          key: 'how-to-calculate-change',
          path: '/faq/prices-and-volumes/how-to-calculate-change',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header2'
          ),
        },
      ],
    },
    {
      text: intl.t('components.header.helpers.menuItems.faqConfig.text10'),
      key: 'cryptocurrency-supply',
      children: [
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text11'),
          key: 'what-is-cryptocurrency-supply',
          path: '/faq/cryptocurrency-supply/what-is-cryptocurrency-supply',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header3'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text12'),
          key: 'what-is-circulating-supply',
          path: '/faq/cryptocurrency-supply/what-is-circulating-supply',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header3'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text13'),
          key: 'what-is-the-total-supply',
          path: '/faq/cryptocurrency-supply/what-is-the-total-supply',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header3'
          ),
        },
        {
          text: intl.t('components.header.helpers.menuItems.faqConfig.text14'),
          key: 'what-is-the-maximum-supply',
          path: '/faq/cryptocurrency-supply/what-is-the-maximum-supply',
          breakAfter: false,
          header: intl.t(
            'components.header.helpers.menuItems.faqConfig.header3'
          ),
        },
      ],
    },
  ],
}

export const projectsForMenu = [
  // {
  //   key: 'liquidity-book',
  //   path: '/liquidity-book',
  //   title: 'Liquidity book',
  //   description: 'Search for the best liquidity available on major exchanges.'
  // },
  {
    key: 'charts',
    path: '/charts',
    title: intl.t('components.header.helpers.menuItems.projectsForMenu.title1'),
    description: intl.t(
      'components.header.helpers.menuItems.projectsForMenu.description1'
    ),
  },
  {
    key: 'glossary',
    path: '/glossary',
    title: 'Glossary',
    description:
      'Learn about the most commonly used cryptocurrency terms and their meanings in our glossary',
  },
  {
    key: 'about',
    path: '/about/generalinfo',
    title: intl.t('components.header.helpers.menuItems.projectsForMenu.title2'),
    description: intl.t(
      'components.header.helpers.menuItems.projectsForMenu.description2'
    ),
  },
  {
    key: 'press-releases',
    path: '/press-releases',
    title: intl.t('components.header.helpers.menuItems.projectsForMenu.title3'),
    description: intl.t(
      'components.header.helpers.menuItems.projectsForMenu.description3'
    ),
  },
  {
    key: 'events',
    path: '/events',
    title: intl.t('components.header.helpers.menuItems.projectsForMenu.title4'),
    description: intl.t(
      'components.header.helpers.menuItems.projectsForMenu.description4'
    ),
  },
  faqConfig,
]
