import Drawer from 'rc-drawer'
import 'rc-drawer/assets/index.css'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const DrawerWrapper = ({ children, className, ...rest }) => {
  const { centered = false } = rest

  return (
    <Drawer
      handler={false}
      level={null}
      {...rest}
      className={clsx(centered ? styles.centeredDrawer : '', className)} // Apply the centered class conditionally
    >
      <div className={clsx(styles.wrapper, className)}>{children}</div>
    </Drawer>
  )
}

export default DrawerWrapper
