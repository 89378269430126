import styles from './styles.module.scss'
import ResetFilters from '../ResetFilters'
import { useSelector } from 'react-redux'
import { getCurrentEntity } from 'src/redux/selectors'
import Image from 'next/image'
import { useRef, useEffect } from 'react'

export const FilterModal = ({ children, closeModal }) => {
  const entity = useSelector(getCurrentEntity)
  const modalRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalRef, closeModal])

  return (
    <div className={styles.blurContainer}>
      <div className={styles.filterModal} ref={modalRef}>
        <div className={styles.close}>
          <Image
            onClick={closeModal}
            className={styles.imagee}
            src='/assets/icons/icon-close-cross.svg'
            width='16px'
            height='16px'
            alt='close'
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>Filters</h2>
            <div className={styles.icon}>
              <Image
                className={styles.imagee}
                src='/assets/icons/icon-filter.svg'
                width='24px'
                height='24px'
                alt='filter'
              />
            </div>
          </div>
          <div className={styles.filterContainer}>{children}</div>
          <div className={styles.buttonContainer}>
            <button className={styles.confirmButton} onClick={closeModal}>
              Save
            </button>
            <ResetFilters entity={entity} />
          </div>
        </div>
      </div>
    </div>
  )
}
