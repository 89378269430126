import { formatPeriodAgo } from 'src/utils/time'
import styles from './styles.module.scss'

export const NewsList = ({ data, handleNewsInteraction }) => {
  const renderNewsItem = (item, index) => (
    <a
      className={styles.link}
      key={index}
      href={item.link}
      target='_blank'
      rel={`noopener noreferrer${item.src !== 'cryptosrus' ? ' nofollow' : ''}`}
      onClick={() => handleNewsInteraction(item.link)}
    >
      <div className={styles.item}>
        <div className={styles.date}>{formatPeriodAgo(item.age)}</div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.name}>{item.name}</div>
      </div>
    </a>
  )

  if (!data) return null

  return (
    <div className={styles.newsList}>
      {data.map((item, index) => renderNewsItem(item, index))}
    </div>
  )
}

export default NewsList
