import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'src/components/Dropdown'
import { LogarithmicRangeFilter } from 'src/components/LogarithmicRangeFilter'
import { SelectBox } from 'src/components/Select'
import { PeriodFilter } from 'src/components/PeriodFilter'
import { HideEntityFilter } from 'src/components/HideEntityFilter'
import { FiltersLayout } from 'src/layouts/FiltersLayout'
import { FilterModal } from '../FiltersLayout/components/FilterModal'
import { coinsFiltersOptions, ENTITY } from 'src/consts'
import {
  addException,
  resetCoinsExceptions,
  changeCoinsPeriod,
  deleteException,
  setCoinsTop,
  setCoinsDependsOn,
  setCoinsRange,
  resetCoinsRange,
  setCoinsRanking,
  setCoinsTag,
} from 'src/redux/thunks'
import {
  getCoinsRange,
  getMapDataMaxValues,
  getDependsOnByEntity,
  getCoinsActivePeriod,
  getCoinsPeriod,
  getCoinsTop,
  getMapDataArrayRaw,
  getCoinsExceptionsDict,
  getCoinsRanking,
  getCoinsTag,
} from 'src/redux/selectors'
import { getFieldOfDataObject } from 'src/utils/currency'
import { coinsFiltersDefaults } from 'src/redux/reducers'

const periodsFormatter = value => {
  return {
    '1h': '1 hour',
    '24h': '1 day',
    '7d': '7 days',
    '30d': '1 month',
    custom: 'Custom',
  }[value]
}

const rangeTitle = dependsOn => {
  return {
    volume: 'Volume range',
    marketCap: 'M.cap range',
  }[dependsOn]
}

export const CoinsFilterLayout = ({
  coinsPage = false,
  classNames,
  isSidebarOpened,
}) => {
  const dispatch = useDispatch()
  const coinsRange = useSelector(getCoinsRange)
  const coinsDepends = useSelector(getDependsOnByEntity)
  const maxValue = getFieldOfDataObject(
    useSelector(getMapDataMaxValues),
    coinsDepends,
    Math.round
  )
  const coinsActivePeriod = useSelector(getCoinsActivePeriod)
  const coinsPeriod = useSelector(getCoinsPeriod)
  const coinsTop = useSelector(getCoinsTop)
  const mapDataArray = useSelector(getMapDataArrayRaw)
  const exceptionsDict = useSelector(getCoinsExceptionsDict)
  const coinsRanking = useSelector(getCoinsRanking)
  const coinsTag = useSelector(getCoinsTag)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const openModal = () => {
    setIsFilterModalOpen(true)
  }

  const closeModal = () => {
    setIsFilterModalOpen(false)
  }

  return (
    <div>
      <FiltersLayout
        flashButton={true}
        openModal={openModal}
        classNames={classNames}
        isSidebarOpened={isSidebarOpened}
      >
        <PeriodFilter
          title='Performance'
          action={data => dispatch(changeCoinsPeriod(data))}
          options={coinsFiltersOptions.period}
          value={coinsActivePeriod}
          periods={coinsPeriod}
          defaultValue={coinsFiltersDefaults.period.active}
          valueFormatter={periodsFormatter}
        />
        {!coinsPage && (
          <SelectBox
            // label='Block Size:'
            options={coinsFiltersOptions.dependsOn}
            value={coinsDepends}
            defaultValue={coinsFiltersDefaults.dependsOn}
            action={data => dispatch(setCoinsDependsOn(data))}
            instanceId='Block'
            isFilter
          />
        )}
      </FiltersLayout>
      {isFilterModalOpen && (
        <FilterModal closeModal={closeModal}>
          <SelectBox
            label='Show:'
            options={coinsFiltersOptions.ranking}
            value={coinsRanking}
            defaultValue={coinsFiltersDefaults.ranking}
            action={data => dispatch(setCoinsRanking(data))}
            instanceId='Ranking'
            isFilter
          />
          {/* 
          <SelectBox
            label='Tag:'
            options={coinsFiltersOptions.tag}
            value={coinsTag}
            defaultValue={coinsFiltersDefaults.tag}
            action={data => dispatch(setCoinsTag(data))}
            instanceId='Tag'
            isFilter
          /> */}

          {/* <PeriodFilter
            title='Performance'
            action={data => dispatch(changeCoinsPeriod(data))}
            options={coinsFiltersOptions.period}
            value={coinsActivePeriod}
            periods={coinsPeriod}
            defaultValue={coinsFiltersDefaults.period.active}
            valueFormatter={periodsFormatter}
          /> */}

          <SelectBox
            label='Gainers & Losers:'
            options={coinsFiltersOptions.top}
            value={coinsTop}
            defaultValue={coinsFiltersDefaults.top}
            action={data => dispatch(setCoinsTop(data))}
            instanceId='Gainers'
            isFilter
          />

          {/* {!coinsPage && (
            <SelectBox
              label='Block Size:'
              options={coinsFiltersOptions.dependsOn}
              value={coinsDepends}
              defaultValue={coinsFiltersDefaults.dependsOn}
              action={data => dispatch(setCoinsDependsOn(data))}
              instanceId='Block'
              isFilter
            />
          )} */}

          <Dropdown title={rangeTitle(coinsDepends)} showArrow>
            <LogarithmicRangeFilter
              maxValue={maxValue}
              value={coinsRange}
              action={range => dispatch(setCoinsRange(range))}
              reset={range => dispatch(resetCoinsRange(range))}
            />
          </Dropdown>

          <HideEntityFilter
            entity={ENTITY.COIN}
            mapDataArray={mapDataArray}
            currentItemsDict={exceptionsDict}
            addItem={data => dispatch(addException(data))}
            deleteItem={data => dispatch(deleteException(data))}
            reset={() => dispatch(resetCoinsExceptions())}
          />
        </FilterModal>
      )}
    </div>
  )
}

export default CoinsFilterLayout
