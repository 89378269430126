import { useState } from 'react'
import cookie from 'cookie'
import { useRouter } from 'next/router'
import AdButlerPlacement from '../AdButlerPlacement'
import styles from './styles.module.scss'
import clsx from 'clsx'

const getCookieValue = ({ key, cookies, options, defaultValue }) => {
  const value = cookie.parse(cookies || '', options)
  return value[key] ? value[key] !== '0' : defaultValue
}

const cookieKey = 'isStickyBannerShown'
const initialValue = true

// const getInitialValue = () => {
//   if (typeof window === 'undefined' || typeof document === 'undefined')
//     return initialValue

//   return getCookieValue({
//     key: cookieKey,
//     cookies: document.cookie,
//     defaultValue: initialValue,
//   })
// }

export const StickyCorner = ({ position }) => {
  // const [shown, setShown] = useState(getInitialValue())
  const [shown, setShown] = useState(true)
  const router = useRouter()

  const hideBanner = e => {
    e.stopPropagation()
    document.cookie = cookie.serialize(cookieKey, '0', { maxAge: 60 * 60 * 4 }) // 4 hours
    setShown(false)
  }

  const onEmpty = () => setShown(false)

  const isShare = router.asPath.includes('getScreen')
  return (
    shown &&
    !isShare && (
      <div
        className={clsx(
          styles.stickyCorner,
          position === 'left'
            ? styles.stickyCornerLeft
            : styles.stickyCornerRight
        )}
      >
        <button className={styles.close} onClick={hideBanner}>
          Close
        </button>
        <AdButlerPlacement
          className={styles.placement}
          place={position === 'left' ? '611336' : '494697'}
          onEmpty={onEmpty}
          forceProxy={true}
        />
      </div>
    )
  )
}

export default StickyCorner
