import { ReactNode } from 'react'
import clsx from 'clsx'
import Head from 'next/head'
import { Thing } from 'schema-dts'
import { Footer } from 'src/components/Footer'
import { Header } from 'src/components/Header'
import { NewsFeed } from 'src/components/NewsFeed'
import { ScrollToTop } from 'src/components/ScrollToTop'
import { ShareMapHeader } from 'src/components/ShareMapHeader'
import { SeoMeta, SeoMetaProps } from './components/SeoMeta'
import styles from './styles.module.scss'

// this class is used to make a screenshot on /api/share
const SCREENSHOT_CLASS = 'MapPage'

type BaseLayoutProps<T extends Thing> = {
  children: ReactNode
  footer?: boolean
  fixedHeader?: boolean
  newsFeed?: boolean
  /** Use content scroll instead body native scroll */
  scrollInContent?: boolean
  scrollToTop?: boolean
  seo?: SeoMetaProps<T>
  filterToggle?: (val: boolean) => void
  isShare?: boolean
  switcherWatchlist?: boolean
}

export function BaseLayout<A extends Thing>({
  children,
  footer = true,
  fixedHeader = true,
  newsFeed = false,
  scrollToTop = false,
  scrollInContent = false,
  seo,
  filterToggle,
  isShare,
  switcherWatchlist = false,
}: BaseLayoutProps<A>) {
  return (
    <div className={clsx(styles.app)}>
      <Head>
        <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
        <link rel='icon' href='/favicon.ico' type='image/x-icon' />
      </Head>
      {seo && <SeoMeta {...seo} />}
      {!isShare && (
        <Header
          fixed={fixedHeader}
          filterToggle={filterToggle}
          switcherWatchlist={switcherWatchlist}
        />
      )}
      {isShare && <ShareMapHeader />}
      <section
        className={clsx(styles.content, SCREENSHOT_CLASS, {
          [styles.scrollInContent]: scrollInContent,
        })}
      >
        <div className={styles.children}>{children}</div>
        {!isShare && footer && <Footer />}
      </section>
      {!isShare && newsFeed && <NewsFeed />}
      {!isShare && scrollToTop && <ScrollToTop />}
    </div>
  )
}
