import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getListNewsSelector } from 'src/redux/selectors'
import { getListNews } from 'src/redux/thunks'
import { NewsListGlobals } from '../NewsListGlobals'
import { NewsList } from '../NewsList'

import styles from './styles.module.scss'

export const NewsDropOut = ({
  globals,
  currency,
  periodsActive,
  handleNewsInteraction,
}) => {
  const data = useSelector(getListNewsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getListNews())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.newsDropOut}>
      <NewsListGlobals
        globals={globals}
        currency={currency}
        period={periodsActive}
      />
      <NewsList data={data} handleNewsInteraction={handleNewsInteraction} />
    </div>
  )
}

export default NewsDropOut
