import { useRef } from 'react'
import useWidth from 'src/hooks/useWidth'
import { useOverlayTriggerState } from '@react-stately/overlays'
import styles from './styles.module.scss'
import { Dialog } from 'src/components/Dialog'
import { intl } from 'src/i18n'

export const Subscription = () => {
  const now = useRef(Date.now())
  const { isMobile } = useWidth()
  const dialog = useOverlayTriggerState({})

  return (
    <>
      <Dialog
        dismissable
        headerless
        padding={false}
        onClose={dialog.close}
        open={dialog.isOpen}
        title=''
      >
        <iframe
          className={styles.iframe}
          src={`https://cdn.forms-content.sg-form.com/1e4e3f3a-1d48-11ec-8d53-7a833d62f997?query=${now.current}`}
        />
      </Dialog>
    </>
  )
}

export default Subscription
