import styles from './styles.module.scss'
import clsx from 'clsx'
import { SocialBlock } from 'src/components/SocialBlock'
import { Logo } from 'src/components/Logo'
import packageJson from '/package.json'
import { intl } from 'src/i18n'

export const Footer = () => {
  // TODO: fetch app version
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={clsx(styles.contentPart, styles.logo)}>
          <Logo />
        </div>
        <div className={clsx(styles.contentPart, styles.contacts)}>
          <div className={styles.contact}>
            <a href='mailto:contact@coin360.com'>contact@coin360.com</a>
          </div>
          <SocialBlock transparentBackground footer />
        </div>
        <div className={clsx(styles.contentPart, styles.toTopStack)}></div>
        <div className={clsx(styles.contentPart, styles.version)}>
          <div className={styles.textVersion}>v {packageJson.version}</div>
        </div>
        <div className={clsx(styles.contentPart, styles.copyright)}>
          <div className={styles.textContainer}>
            {`© `}
            <span className={styles.launch}>2017 - </span>
            {intl.t('components.footer.reserved', {
              year: new Date().getFullYear(),
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
