import { useDispatch } from 'react-redux'
import { ENTITY } from 'src/consts'
import { resetSharedFiltersAction } from 'src/redux/actions'
import {
  resetCoinsFiltersAll,
  resetExchangesFiltersAll,
} from 'src/redux/thunks'
import styles from './styles.module.scss'

export const ResetFilters = ({ entity }) => {
  const dispatch = useDispatch()

  const resetAction = {
    [ENTITY.COIN]: () => {
      dispatch(resetCoinsFiltersAll())
      dispatch(resetSharedFiltersAction())
    },
    [ENTITY.EXCHANGE]: () => {
      dispatch(resetExchangesFiltersAll())
      dispatch(resetSharedFiltersAction())
    },
  }[entity]

  return (
    <div className={styles.controls}>
      <div className={styles.reset} onClick={resetAction}>
        Reset
      </div>
    </div>
  )
}

export default ResetFilters
