import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { getDirectionByChange } from 'src/utils/common'
import { formatChangeValue } from 'src/utils/number/number'
import { formatValueWithCurrencyOB } from 'src/utils/currency'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const NewsFeedGlobals = ({ period, globals, currency }) => {
  const globalMarketCap = formatValueWithCurrencyOB(globals.marketCap, currency)
  const globalMarketCapChange = `(${formatChangeValue(
    globals.changeMarketCap,
    false
  )}%)`
  const globalVolume = formatValueWithCurrencyOB(globals.volume, currency)
  const globalVolumeChange = `(${formatChangeValue(
    globals.changeVolume,
    false
  )}%)`

  if (!(period && typeof period === 'string')) {
    period = ''
  } else {
    switch (period.toLowerCase()) {
      case '1h':
      case '24h':
      case '7d':
      case '30d':
        period = period
        break
      case 'custom':
        period = ''
        break
      default:
        period = ''
    }
  }

  const volumeDirection = getDirectionByChange(globals.changeVolume || 0)
  const mCapDirection = getDirectionByChange(globals.changeMarketCap || 0)

  return (
    <Link href='/charts' className={styles.link} prefetch={false}>
      <a className={styles.newsFeedGlobals}>
        <div className={styles.item}>
          <div className={styles.itemLabel}>
            {intl.t('components.newsFeed.components.newsFeedGlobals.vol24')}
          </div>
          <div className={styles.delimiter} />
          <div className={clsx(styles.itemBody, styles[volumeDirection])}>
            {globalVolume ? globalVolume : '?'}
          </div>
          <div className={clsx(styles.itemChange, styles[volumeDirection])}>
            {globalVolumeChange}
          </div>
        </div>
        <div className={styles.delimiter} />
        <div className={styles.item}>
          <div className={styles.itemLabel}>
            {intl.t('components.newsFeed.components.newsFeedGlobals.mCap')}
          </div>
          <div className={styles.delimiter} />
          <div className={clsx(styles.itemBody, styles[mCapDirection])}>
            {globalMarketCap ? globalMarketCap : '?'}
          </div>
          <div className={clsx(styles.itemChange, styles[mCapDirection])}>
            {globalMarketCapChange}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default NewsFeedGlobals
