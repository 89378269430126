import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Image from 'next/image'
import { List, AutoSizer } from 'react-virtualized'
import { ReactNode, useState } from 'react'

type Props = {
  className?: string
  icon?: boolean
  onChangeSearch: (search: string) => void
  options: any[]
  rowRenderer: (item: any) => ReactNode
  onSelect: (item: any) => void
  placeholder: string
  rowHeight: number
  search: string
}

const DesktopView = ({
  className,
  icon = true,
  onChangeSearch,
  onSelect,
  options,
  placeholder,
  rowHeight = 48,
  rowRenderer,
  search,
}: Props) => {
  const [listVisible, setListVisible] = useState(false)

  const onFocus = () => setListVisible(true)

  const onClickOverlay = () => setListVisible(false)

  const handleSearch = event => onChangeSearch(event.target.value)

  const onClear = () => onChangeSearch('')

  const handleSelect = selectedValue => {
    onChangeSearch('')
    setListVisible(false)
    onSelect(selectedValue)
  }

  const renderNoOptions = () => null

  const handleRowRenderer = ({ key, index, style }) => {
    const currentData = { ...options[index], valueSearch: search }

    return (
      <div
        key={key}
        style={style}
        onClick={() => handleSelect(currentData)}
        className={clsx('ListItemWrapper')}
      >
        {rowRenderer(currentData)}
      </div>
    )
  }

  const renderList = () => {
    if (!listVisible) return null
    if (!options?.length) return renderNoOptions()

    return (
      <>
        <div className={styles.overlay} onClick={onClickOverlay} />
        <div className={styles.listWrap}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={options?.length}
                rowHeight={rowHeight}
                rowRenderer={handleRowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </>
    )
  }

  return (
    <div className={clsx(styles.searchBox, 'unselectable', className)}>
      <div className={styles.inputBox}>
        {/* {icon && (
          <Image
            className={styles.icon}
            src='/assets/icons/icon-search-grey.svg'
            width='16px'
            height='16px'
            alt='Search icon'
          />
        )} */}
        <input
          className={styles.input}
          onChange={handleSearch}
          onFocus={onFocus}
          placeholder={placeholder}
          value={search}
        />

        {search && (
          <Image
            alt='Close icon'
            className={styles.closeIcon}
            height='16px'
            src='/assets/icons/icon-close.svg'
            onClick={onClear}
            width='16px'
          />
        )}
      </div>
      {renderList()}
    </div>
  )
}

export default DesktopView
