export const fiatRegions = {
  Europe: [
    'ALL',
    'EUR',
    'BYN',
    'BAM',
    'BGN',
    'CZK',
    'DKK',
    'HUF',
    'ISK',
    'MDL',
    'NOK',
    'PLN',
    'RON',
    'RUB',
    'RSD',
    'SEK',
    'CHF',
    'UAH',
    'GBP',
  ],
  Asia: [
    'AFN',
    'AMD',
    'AZN',
    'BHD',
    'BDT',
    'BTN',
    'BND',
    'KHR',
    'CNY',
    'GEL',
    'INR',
    'IDR',
    'IRR',
    'IQD',
    'ILS',
    'JPY',
    'JOD',
    'KZT',
    'KWD',
    'KGS',
    'LAK',
    'LBP',
    'MYR',
    'MVR',
    'MNT',
    'MMK',
    'NPR',
    'KPW',
    'OMR',
    'PKR',
    'PHP',
    'QAR',
    'SAR',
    'SGD',
    'KRW',
    'LKR',
    'SYP',
    'TWD',
    'TJS',
    'THB',
    'TRY',
    'TMT',
    'AED',
    'UZS',
    'VND',
    'YER',
  ],
  Africa: [
    'DZD',
    'AOA',
    'XOF',
    'BWP',
    'BIF',
    'CVE',
    'XAF',
    'KMF',
    'CDF',
    'DJF',
    'EGP',
    'ERN',
    'SZL',
    'ETB',
    'GMD',
    'GHS',
    'GNF',
    'KES',
    'LSL',
    'LRD',
    'LYD',
    'MGA',
    'MWK',
    'MRU',
    'MUR',
    'MAD',
    'MZN',
    'NAD',
    'NGN',
    'RWF',
    'STN',
    'SCR',
    'SLL',
    'SOS',
    'ZAR',
    'SSP',
    'SDG',
    'TZS',
    'TND',
    'UGX',
    'ZMW',
    'ZWL',
  ],
  NorthAmerica: [
    'XCD',
    'BSD',
    'BBD',
    'BZD',
    'CAD',
    'CRC',
    'CUP',
    'DOP',
    'GTQ',
    'HTG',
    'HNL',
    'JMD',
    'MXN',
    'NIO',
    'PAB',
    'TTD',
    'USD',
  ],
  Oceania: ['AUD', 'FJD', 'NZD', 'PGK', 'WST', 'SBD', 'TOP', 'VUV'],
  SouthAmerica: [
    'ARS',
    'BOB',
    'BRL',
    'CLP',
    'COP',
    'GYD',
    'PYG',
    'PEN',
    'SRD',
    'UYU',
    'VES',
  ],
}
