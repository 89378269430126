export const getRangeFilterMarks = (max, marksType, supplement) => {
  let marks =
    marksType === 'logarithmic'
      ? getMarksForProvidedMaxLog(max)
      : getMarksForProvidedMax(max)
  marks = supplement ? { ...marks, ...supplement } : marks

  marks = Object.entries(marks).reduce((acc, entry) => {
    acc[
      marksType === 'logarithmic'
        ? entry[0] === '0'
          ? '0'
          : Math.log(Number(entry[0]))
        : Number(entry[0])
    ] = entry[1]
    return acc
  }, {})

  return marks
}

const getMarksForProvidedMaxLog = maxValue => {
  let result = []

  if (maxValue > 10000000000) {
    result = [0, 100, 10000, 1000000, 1000000000]
  } else if (maxValue > 1000000000) {
    result = [0, 100, 10000, 1000000, 100000000]
  } else {
    result = [0, 100, 1000, 10000, 100000]
  }

  return result.reduce((acc, next) => {
    acc[next] = marksToLabelMap[next]
    return acc
  }, {})
}

// TODO: improve linear logic to support more cases
const getMarksForProvidedMax = maxValue => {
  const result = {}

  for (let i = 0; i < 4; i++) {
    result[(maxValue / 4) * i] = 'no value'
  }

  return result
}

const marksToLabelMap = {
  0: '0',
  100: '100',
  1000: '1k',
  10000: '10k',
  100000: '100k',
  500000: '0.5m',
  1000000: '1m',
  50000000: '50m',
  100000000: '100m',
  500000000: '500m',
  1000000000: '1b',
  100000000000: '100b',
}
