import { useState, useEffect, useRef } from 'react'
import InnerHTML from 'dangerously-set-html-content'
import useIsInViewport from './useIsInViewport'

const ACCOUNT_ID = 182118
const AD_BUTLER_SERVER = 'https://servedbyadbutler.com/'

function extractTargetRedirect(url) {
  const parts = url.split(AD_BUTLER_SERVER)
  if (!!parts[1]) {
    return AD_BUTLER_SERVER + parts[1]
  } else {
    const decodedPath = decodeURIComponent(url)
    const decodedParts = decodedPath.split(AD_BUTLER_SERVER)
    if (!!decodedParts[1]) {
      return AD_BUTLER_SERVER + decodedParts[1]
    } else {
      return '#'
    }
  }
}

function extractTargetUrlInBody(body, redirectUrl) {
  if (!body) {
    return ''
  }
  if (!document) return body
  const holder = document.createElement('div')
  holder.innerHTML = body
  const anchorsArr = [...holder.querySelectorAll('a')]
  anchorsArr.forEach(a => {
    a.href = extractTargetRedirect(redirectUrl)
  })
  return holder.innerHTML
}

async function getUserIP() {
  if (!window.userIP) {
    window.userIP = await fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(res => res.ip)
  }

  return window.userIP
}

const proxyData = async (place, rcb, rct) => {
  try {
    const ip = await getUserIP()
    const reqUrl =
      `${AD_BUTLER_SERVER}adserve/;ID=${ACCOUNT_ID};setID=${place};type=json;click=CLICK_MACRO_PLACEHOLDER;ip=${ip}` +
      (!!rcb && !!rct ? `&rcb=${rcb}&rct=${rct}` : '')
    // `/api/artificial-butter?place=${place};ip=${ip}` +
    // (!!rcb && !!rct ? `&rcb=${rcb}&rct=${rct}` : '')

    const requestAd = await fetch(reqUrl)
    const ad = await requestAd.json()
    const placementName = Object.keys(ad.placements)[0]
    if (!!placementName && placementName.length > 0) {
      return ad.placements[placementName]
    }
    return null
  } catch (err) {
    return null
  }
}

const renderProxyData = data => {
  if (data == null) return null
  const {
    eligible_url,
    viewable_url,
    redirect_url,
    image_url,
    target,
    alt_text,
    width,
    height,
    body,
  } = data
  const target_url = extractTargetRedirect(redirect_url)
  const content_body = extractTargetUrlInBody(body, redirect_url)

  /* eslint-disable */
  return image_url.length > 0 ? (
    <div eligible-callback={eligible_url} viewable-callback={viewable_url} viewable='true'>
      <a href={target_url} target={target} rel='noopener noreferrer nofollow'>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={image_url} alt='banner' title={alt_text} border='0' width={width} height={height} />
      </a>
    </div>
  ) : (
    <InnerHTML html={content_body} />
  )
  /* eslint-enable */
}

const ProxyPlacement = ({ place, uid, className, onEmpty }) => {
  const [data, setData] = useState(null)
  const [viewable, setViewable] = useState(false)
  const containerRef = useRef()
  const isInViewport = useIsInViewport(containerRef)
  useEffect(() => {
    if (data == null) {
      async function FetchData() {
        const requestedData = await proxyData(place)
        if (!!requestedData) {
          setData(requestedData)
        } else if (typeof onEmpty == 'function') {
          onEmpty()
        }
      }
      FetchData()
    }
  }, [data, place])

  useEffect(() => {
    if (isInViewport && !viewable && data != null) {
      setViewable(true)
      const { eligible_url, viewable_url } = data
      fetch(extractTargetRedirect(viewable_url)).catch(error => {
        console.log('fetch viewable error:', error)
      })
      setTimeout(() => {
        fetch(extractTargetRedirect(eligible_url)).catch(error => {
          console.log('fetch eligible error:', error)
        })
      }, 2000)
    }
  }, [viewable, isInViewport, data])
  return (
    <span
      id={`placement_${place}_${uid}`}
      className={className}
      ref={containerRef}
    >
      {renderProxyData(data)}
    </span>
  )
}

export default ProxyPlacement
