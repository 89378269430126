import { useState, useEffect, useRef } from 'react'

const ACCOUNT_ID = 182118

const NativePlacement = ({ place, uid, adjustHeight, onEmpty, className }) => {
  const [rendered, setRendered] = useState(false)
  const [height, setHeight] = useState('auto')
  const containerRef = useRef()

  useEffect(() => {
    if (!rendered) {
      try {
        window.AdButler.ads.push({
          handler: function (opt) {
            window.AdButler.register(
              ACCOUNT_ID,
              place,
              [0, 0],
              `placement_${place}_${uid}`,
              opt
            )
            if (adjustHeight) {
              setTimeout(() => {
                if (
                  !!containerRef.current &&
                  !!containerRef.current.getElementsByTagName('iframe')[0]
                ) {
                  const contentHeight = parseInt(
                    containerRef.current.getElementsByTagName('iframe')[0][
                      'offsetHeight'
                    ]
                  )
                  setHeight(`${contentHeight}px`)
                }
              }, 2000)
            }
          },
          opt: {
            place: `${place}_${uid}`,
            keywords: window.abkw,
            domain: 'servedbyadbutler.com',
          },
        })
      } catch (error) {
        onEmpty()
        // setLoadFailed(true)
      }
      setRendered(true)
    }
  }, [rendered])

  return (
    <div
      id={`placement_${place}_${uid}`}
      className={className}
      ref={containerRef}
      style={{ height: height }}
    ></div>
  )
}

export default NativePlacement
