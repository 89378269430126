import { useImperativeHandle, useRef, forwardRef } from 'react'
import Head from 'next/head'
import { MAIN_SITE_IMAGE, WEBSITE, SITENAME } from 'src/consts'
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'
import { NewsFeed } from 'src/components/NewsFeed'
import { ScrollToTop } from 'src/components/ScrollToTop'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const ListLayout = forwardRef(
  (
    {
      children,
      title = intl.t('layouts.listLayout.title'),
      description = intl.t('layouts.listLayout.description'),
      uri = '',
      pageMeta = false,
      scrollToTop = false,
      switcherWatchlist = false,
      withFooter = true,
      newsFeedData,
    },
    ref
  ) => {
    const mainRef = useRef(null)

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        mainRef.current.scrollTo(0, 0)
      },
    }))

    return (
      <>
        <Head>
          <title>{title}</title>
          <link rel='shortcut icon' href={'/favicon.ico'} type='image/x-icon' />
          <link rel='icon' href={'/favicon.ico'} type='image/x-icon' />
          <meta name='description' content={description} />
          <meta name='image' content={`${MAIN_SITE_IMAGE}`} />
          <meta property='og:title' content={title} />
          <meta property='og:url' content={`${WEBSITE}${uri}`} />
          <meta property='og:image' content={`${MAIN_SITE_IMAGE}`} />
          <meta property='og:image:type' content='image/png' />
          <meta property='og:image:width' content='600' />
          <meta property='og:image:height' content='315' />
          <meta property='og:site_name' content='COIN360' />
          <meta property='og:description' content={description} />
          <meta property='og:type' content='website' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:site' content='@COIN360com' />
          <meta name='twitter:description' content={description} />
          <meta name='twitter:image' content={`${MAIN_SITE_IMAGE}`} />
          {pageMeta || (
            <script
              type='application/ld+json'
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  '@context': 'http://schema.org/',
                  '@type': 'WebPage',
                  name: title,
                  description: description,
                  url: `${WEBSITE}${uri}`,
                  image: MAIN_SITE_IMAGE,
                  sameAs: ['https://twitter.com/coin360com'],
                }),
              }}
            />
          )}
          <link rel='canonical' href={`${WEBSITE}${uri}`} />
        </Head>
        <div className={styles.app}>
          <Header switcherWatchlist={switcherWatchlist} />
          <main className={styles.main} ref={mainRef}>
            {children}
          </main>
        </div>
      </>
    )
  }
)
