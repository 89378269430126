import clsx from 'clsx'
import AdButlerPlacement from '../AdButlerPlacement'
import styles from './styles.module.scss'

export const CoinButtonBanner = ({ place, className }) => {
  return (
    <div
      className={clsx(styles.coinButton, place, className)}
      style={{ height: 40 }}
    >
      <AdButlerPlacement place={place} forceProxy={true} />
    </div>
  )
}

export default CoinButtonBanner
